import {
  CreatorRoutes,
  EventRoutes,
  HomeRoutes,
  IdentityRoutes,
  ProfileRoutes,
} from 'navigation/routes';

export const Routes = {
  ...CreatorRoutes,
  ...EventRoutes,
  ...HomeRoutes,
  ...IdentityRoutes,
  ...ProfileRoutes,
};