import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  Images,
  MediaQueries,
  Theme,
} from 'common';

import {
  H4,
  PSmall,
  Text,
} from 'common/components/text';

import {
  Card,
} from './Card';

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0 0 10px;
  height: 38px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: ${props => props.width || '220px'};
  padding-bottom: 56.25%;

  ${props => props.onClick && 'cursor: pointer;'}
`;

const Image = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const DetailsContainer = styled.div`
  width: 100%;
  padding: 3%;
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid ${Theme.Divider.Color};

  @media only screen and ${MediaQueries.Md} {
    padding: 5%;
  }
`;

const FavButton = styled.div`
  position: absolute;
  background-color: ${Colors.White};
  bottom: 0;
  margin-left: -15px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  left: 50%;
  background-image: url(${Images.Icons.Heart});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  cursor: pointer;
`;

const Callout = styled.div`
  border-radius: 5px;
  background-color: ${Theme.EventCard.Banner.SoldOutColor};
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  color: ${Colors.White};
`;

const DateInfo = styled.div`
  border-radius: 6px;
  background-color: ${Colors.GreyBrown};
  padding: 5px;
  color: ${Colors.White};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 38px;
`;

const Price = styled.div`
  color: ${Theme.Text.Color};
  font-size: ${FontSizes.Regular};
  font-family: ${FontFamilies.Bold};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 0 0 10px;
  height: 38px;
`;

const PricePrefix = styled.span`
  font-size: ${FontSizes.Smaller};
  font-family: ${FontFamilies.Regular};
  padding: 0 0 3px 0;
`;

export const EventCard = props => {

  const onFavClick = () => {
    typeof props.onFavClick === 'function' && props.onFavClick(props.onFavClickArgs);
  };

  const onCardClick = () => {
    typeof props.onClick === 'function' && props.onClick();
  };

  return (

    <Card
      position={'relative'}
      flexDirection={'column'}
      border={Theme.EventCard.Border}
      boxShadow={Theme.EventCard.BoxShadow}
      width={'100%'}
      height={'100%'}
      onClick={onCardClick}>
    
      <ImageContainer
        width={props.width}
        height={props.height}>

        <Image
          src={props.image}
        />

        { !props.hideFavButton &&

          <FavButton
            onClick={onFavClick}
          />
        }

        { props.callout &&

          <Callout>{props.callout}</Callout>
        }

      </ImageContainer>

      <DetailsContainer>

        { props.showDate &&
          
          <DateInfo>

            <Text color={Colors.White} fontSize={FontSizes.Smallest} lineHeight={1}>{props.month}</Text>
            <Text color={Colors.White} lineHeight={1} fontFamily={FontFamilies.Bold} fontSize={FontSizes.Big}>{props.day}</Text>
            <Text color={Colors.White} fontSize={FontSizes.Smallest} lineHeight={1}>{props.time}</Text>

          </DateInfo>
        }

        <TextContainer>

          <H4
            margin={'0'}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            lineHeight={1}>

              {props.eventText}
          </H4>

          <PSmall
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            lineHeight={1}>

              {props.location}
          </PSmall>

        </TextContainer>

        <Price>
          <PricePrefix>{props.pricePrefix || ''}</PricePrefix>
          <span>{props.price}</span>
        </Price>

      </DetailsContainer>
    </Card>
  );
};