import
  React, { useEffect, useState }
from 'react';

import
  styled
from 'styled-components';

import {
  FontSizes,
  MediaQueries,
  Theme,
} from 'common';

import {
  Text,
  Modal,
} from 'common/components';

import {
  WeTicketLogo,
  WeTicketShortLogo
} from 'common/components/svg';

import {
  HtmlModal
} from 'ui/components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 10px;

  @media only screen and ${MediaQueries.Md} {
    padding: 30px 0;
  }
`;

const FooterItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and ${MediaQueries.Md} {
    flex-direction: row;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${props => props.flex && `flex: ${props.flex};`}

  @media only screen and ${MediaQueries.Md} {
    width: auto;
  }
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px 0;
`;

const FooterMobileDivider = styled.div`
  height: 1px;
  margin: 7px 0 15px 0;
  width: 100%;
  background-color: ${Theme.Footer.DividerColor};

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

const FooterLogoContainer = styled.div`
  align-items: center;
  padding: 0 0 10px 0;
  display: ${props => props.hidden ? 'none' : 'flex'};

  @media only screen and ${MediaQueries.Md} {
    display: flex;
    opacity: ${props => props.hidden ? 0 : 1};
  }
`;

const WeTicketShortLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0 0;
  height: 120px;

  @media only screen and ${MediaQueries.Md} {
    height: 160px;
  }
`;

export const Footer = () => {

  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);

  useEffect(() => {
    window.policyTimestamp = new Date().getTime();
  }, []);

  return (

    <Container>

      <FooterItemContainer>

        <FooterColumn flex={1}>

          <FooterRow>
            <FooterLogoContainer>
              <WeTicketLogo
                height={36}
                width={170}
                color={Theme.Footer.TextColor}
              />

            </FooterLogoContainer>
          </FooterRow>

          <FooterRow>
            <Text onClick={() => window.open('https://weticket.co.za', '_blank')} color={Theme.Footer.TextColor} fontSize={FontSizes.Small}>Buy Tickets</Text>
          </FooterRow>
          <FooterRow>
            <Text onClick={() => setShowContactForm(true)} color={Theme.Footer.TextColor} fontSize={FontSizes.Small}>Join as Organiser</Text>
          </FooterRow>
          <FooterRow>
            <Text onClick={() => window.open('https://features.weticket.co.za', '_blank')} color={Theme.Footer.TextColor} fontSize={FontSizes.Small}>App Features</Text>
          </FooterRow>

        </FooterColumn>

        <FooterMobileDivider />

        <FooterColumn flex={1}>

          <FooterLogoContainer hidden={true}>
            <WeTicketLogo
              height={36}
              width={170}
            />
          </FooterLogoContainer>

          <FooterRow>
              <Text onClick={() => setShowContactForm(true)} color={Theme.Footer.TextColor} fontSize={FontSizes.Small}>Contact Us</Text>
            </FooterRow>
            <FooterRow>
              <Text onClick={() => window.open('mailto:support@weticket.co.za')} color={Theme.Footer.TextColor} fontSize={FontSizes.Small}>Support</Text>
            </FooterRow>

        </FooterColumn>

        <FooterMobileDivider />

        <FooterColumn flex={1}>

          <FooterLogoContainer hidden={true}>
            <WeTicketLogo
              height={36}
              width={170}
            />
          </FooterLogoContainer>

          <FooterRow>
            <Text color={Theme.Footer.TextColor} fontSize={FontSizes.Small} onClick={() => setShowTerms(true)}>Terms of Use</Text>
          </FooterRow>
          <FooterRow>
            <Text color={Theme.Footer.TextColor} fontSize={FontSizes.Small} onClick={() => setShowPrivacyPolicy(true)}>Privacy Policy</Text>
          </FooterRow>

        </FooterColumn>

        <FooterRow>
          <WeTicketShortLogoContainer>
            <WeTicketShortLogo height={'100%'} width={'100%'} color={Theme.Footer.TextColor} />
          </WeTicketShortLogoContainer>
        </FooterRow>

      </FooterItemContainer>

      <Text color={Theme.Footer.TextColor} padding={'15px 0 0 0'} fontSize={FontSizes.Smaller}>© 2025 Cypress Solutions (Pty) Ltd. All rights reserved.</Text>

      <HtmlModal url={'https://storage.googleapis.com/website777/weTicket/diazSummerSplash/weticket_terms.html?v=' + window.policyTimestamp} show={showTerms} onClose={() => setShowTerms(false)}/>
      <HtmlModal url={'https://storage.googleapis.com/website777/weTicket/diazSummerSplash/weticket_privacy_policy.html?v=' + window.policyTimestamp} show={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)}/>
    
      <Modal show={showContactForm} onClose={() => setShowContactForm(false)}>
        <div style={{width: '800px', height: '70vh', maxWidth: '85vw'}}>
          <iframe 
            title={'Contact Us'} 
            src={'https://features.weticket.co.za/contactUs/contact.html?v=' + window.policyTimestamp} 
            width={'100%'} 
            height={'100%'}
            style={{backgroundColor: 'white', border: 'none'}}
          />
        </div>
      </Modal>
    </Container>
  );
};