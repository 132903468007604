import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  TextInput,
  OtpInput,
  Text
} from 'common/components';
import { AuthProvider } from 'providers';
import {
  DataStore,
  FontFamilies,
  FontSizes,
  Theme,
  Logger,
  MediaQueries,
  isValidEmail,
  isValidProviderResult
} from 'common';
import { withCommon } from 'common/hocs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  height: 100%;
  min-height: 480px;
  padding: 15px 15px 50px 15px;

  @media only screen and ${MediaQueries.Md} {
    min-height: 600px;
    padding: 0 0 80px 0;
  }
`;

const ErrorText = styled(Text)`
  color: #c10101;
  font-size: ${FontSizes.Smaller};
`;

const ResendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const ResendText = styled(Text)`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  color: ${Theme.Text.Color};
  text-decoration: underline;
`;

const ResendMessage = styled(Text)`
  color: ${Theme.Text.Color};
  font-size: ${FontSizes.Smaller};
`;

const OtpLoginComponent = ({ common }) => {
  const [state, setState] = useState({
    currentView: 'email', // 'email' or 'otp'
    email: '',
    otp: '',
    error: '',
    guid: ''
  });

  const [resendState, setResendState] = useState({
    countdown: 0,
    message: '',
  });

  const onEmailChange = (val) => {
    setState(prev => ({
      ...prev,
      email: val,
      error: ''
    }));
  };

  const onOtpChange = (value) => {
    setState(prev => ({
      ...prev,
      otp: value,
      error: ''
    }));
  };

  const sendOtp = async () => {
    if (!isValidEmail(state.email)) {
      setState(prev => ({
        ...prev,
        error: 'Please enter a valid email address'
      }));
      return;
    }

    setState(prev => ({ ...prev, error: '' }));

    try {
      common.showLoading();
      const response = await AuthProvider.requestCode(state.email);

      if (!isValidProviderResult(response)) {
        throw new Error(response.message);
      }

      setState(prev => ({
        ...prev,
        currentView: 'otp',
        guid: response.data.guid
      }));
    } catch (error) {
      Logger.error('OtpLoginComponent', 'sendOtp', error);
      common.showToast('Failed to send OTP. Please try again.', 'error');
    } finally {
      common.hideLoading();
    }
  };

  const verifyOtp = async () => {
    if (!state.otp || state.otp.length !== 4) {
      setState(prev => ({
        ...prev,
        error: 'Please enter a valid 6-digit OTP'
      }));
      return;
    }

    setState(prev => ({ ...prev, error: '' }));

    try {
      common.showLoading();
      const response = await AuthProvider.submitCode(state.guid, state.otp, false);

      if (!isValidProviderResult(response)) {
        throw new Error(response.message);
      }

      if (!response.data.verified) {
        common.showToast('Incorrect OTP.  Please check your OTP and try again.', 'error');
        return;
      }

      DataStore.set('ACCESS_TOKEN', response.data.token);
      DataStore.set('LOGGED_IN_EMAIL', state.email);
      
      common.navigate('/profile/view-my-tickets');
    } catch (error) {
      Logger.error('OtpLoginComponent', 'verifyOtp', error);
      common.showToast('Invalid OTP. Please try again.', 'error');
    } finally {
      common.hideLoading();
    }
  };

  const resendOtp = async () => {
    if (resendState.countdown > 0) return;
    setState(prev => ({ ...prev, error: '' }));
    
    try {
      common.showLoading();
      const response = await AuthProvider.resendCode(state.guid);
      if (!isValidProviderResult(response)) {
        throw new Error(response.message);
      }

      setResendState({
        countdown: 30,
        message: 'OTP has been sent to your email'
      });

      setTimeout(() => {
        setResendState(prev => ({
          ...prev,
          message: ''
        }));
      }, 5000);

    } catch (error) {
      Logger.error('OtpLoginComponent', 'resendOtp', error);
      common.showToast('Failed to resend OTP. Please try again.', 'error');
    } finally {
      common.hideLoading();
    }
  };

  useEffect(() => {
    let timer;
    if (resendState.countdown > 0) {
      timer = setInterval(() => {
        setResendState(prev => ({
          ...prev,
          countdown: prev.countdown - 1
        }));
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [resendState.countdown]);

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []); 

  return (
    <>
    <Container>
      <Content>
        {state.currentView === 'email' ? (
          <>
            <Text fontFamily={FontFamilies.Bold} fontSize={FontSizes.Bigger} textAlign={'center'} padding={'0 0 15px 0'}>
              VIEW YOUR TICKETS
            </Text>
            <Text fontSize={FontSizes.Small} textAlign={'center'} padding={'0 0 15px 0'}>
              Please enter the email address you used to purchase your tickets
            </Text>
            
            <TextInput
              type="email"
              placeholder="Email address..."
              value={state.email}
              onChange={onEmailChange}
              width="100%"
            />

            {state.error && <ErrorText>{state.error}</ErrorText>}

            <Button
              text="Send OTP"
              onClick={sendOtp}
              width="100%"
            />
          </>
        ) : (
          <>
            <Text fontFamily={FontFamilies.Bold} fontSize={FontSizes.Bigger} textAlign={'center'} padding={'0'}>
              ENTER YOUR OTP
            </Text>

            <Text fontSize={FontSizes.Small} textAlign={'center'}>
              We've sent a 4-digit code to {state.email}
            </Text>

            <OtpInput
              value={state.otp}
              onChange={onOtpChange}
              numInputs={4}
              separator={<span style={{ width: '8px' }}></span>}
              isInputNum
              shouldAutoFocus
            />

            {state.error && <ErrorText>{state.error}</ErrorText>}

            <Button
              text="Verify"
              onClick={verifyOtp}
              width="100%"
              margin={'10px 0'}
            />

            <ResendContainer>
              <ResendText
                onClick={resendOtp}
                disabled={resendState.countdown > 0}
                fontSize={FontSizes.Small}
              >
                {resendState.countdown > 0 
                  ? `Resend OTP (${resendState.countdown}s)` 
                  : 'Resend OTP'}
              </ResendText>
              
              {resendState.message && (
                <ResendMessage>
                  {resendState.message}
                </ResendMessage>
              )}
            </ResendContainer>
          </>
        )}
      </Content>
    </Container>
    </>
  );
};

export const OtpLogin = withCommon(OtpLoginComponent, {
  showLoadingOnLoad: false
});