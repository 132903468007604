import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  Theme,
} from 'common';

import {
  PSmaller,
  Text,
} from 'common/components';

const Container = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 0 10px 0'};
  width: ${props => props.width || '250px'};
`;

const InputContainer = styled.div`
  border: ${props => props.hasError ? Theme.TextInput.BorderError : Theme.TextInput.Border};
  background-color: ${props => props.backgroundColor || Theme.TextInput.Background};
  display: flex;
  padding: 5px 15px;
  position: relative;
  border-radius: ${Theme.TextInput.BorderRadius};
  align-items: center;
  width: 100%;
  height: ${props => props.height || '40px'};
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${Theme.TextInput.Color};
    transition: background-color 5000s ease-in-out 0s;
`;

const InputShared = color => `
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    color: ${color || Colors.Grey};
    font-style: italic;
  }
  ::-moz-placeholder {
    color: ${color || Colors.Grey};
    font-style: italic;
  }
  :-ms-input-placeholder {
    color: ${color || Colors.Grey};
    font-style: italic;
  }
  :-moz-placeholder {
    color: ${color || Colors.Grey};
    font-style: italic;
  }
  :placeholder {
    color: ${color || Colors.Grey};
    font-style: italic;
  }
`;

const Input = styled.input`
  border: 0 !important;
  background-color: ${Theme.TextInput.Background} !important;
  color: ${Theme.Text.Color} !important;
  font-size: ${FontSizes.Small} !important;
  width: 100% !important;

  ${props => props.type === 'number' &&
  `
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    [type=number] {
      -moz-appearance: textfield;
    }`
  }

  ${props => InputShared(props.placeholderColor)};
`;

const TextArea = styled.textarea`
  border: 0;
  background-color: ${Theme.TextInput.Background};
  color: ${Theme.Text.Color};
  font-family: ${FontFamilies.Regular};
  font-size: ${FontSizes.Small};
  width: 100%;
  height: ${props => props.height || '120px'};
  padding: 5px 0;
  min-width: 100%;

  ${props => InputShared(props.placeholderColor)};
`;

const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  top: 5px;
  right: 3px;
  position: relative;
`;

export const TextInput = props => {

  const [value, valueSetState] = React.useState(props.value || props.defaultValue || '');

  const onClick = () => {
    typeof props.onClick === 'function' && props.onClick();
  };

  const onChange = e => {

    if (!e || !e.target) {
      return;
    }

    const val = e.target.value;

    if (!props.multiline) {
      valueSetState(val);
    }

    typeof props.onChange === 'function' && props.onChange(val);
  };

  React.useEffect(() => {

      valueSetState(props.value || props.defaultValue || '');
    },
    [props.value, props.defaultValue]
  );

  return (

    <Container
      margin={props.margin}
      width={props.width}
      onClick={onClick}>

      { props.label &&
      
        <Text
          fontSize={FontSizes.Small}
          padding={'0 0 10px 0'}>

            {props.label}
        </Text>
      }

      <InputContainer
        height={props.height || (props.multiline && 'auto')}
        hasError={props.hasError}
        backgroundColor={props.backgroundColor}>

        { props.prefix &&

          <Text
            fontSize={FontSizes.Small}
            padding={'0 15px 0 0'}
            whiteSpace={'nowrap'}>

              {props.prefix}
          </Text>
        }
        
        { props.multiline
        
          ? <TextArea
              id={props.id}
              placeholder={props.placeholder || 'ENTER TEXT...'}
              placeholderColor={props.placeholderColor}
              autoComplete={'off'}
              onChange={onChange}
              value={props.value}
              defaultValue={props.defaultValue}
              readOnly={props.readonly || false}
              height={props.height}
            />

          : <Input
              id={props.id}
              type={props.type || 'text'}
              placeholder={props.placeholder || 'ENTER TEXT...'}
              placeholderColor={props.placeholderColor}
              autoComplete={props.type === 'email' ? 'email' : 'off'}
              onChange={onChange}
              value={value}
              readOnly={props.readonly || false}
            />
        }

        { props.postFix &&

          <Text
            fontSize={FontSizes.Small}
            padding={'0 0 0 15px'}
            whiteSpace={'nowrap'}>

              {props.postFix}
          </Text>
        }

      </InputContainer>

      { props.hasError && props.errorMessage &&

        <ErrorMessage>

          <PSmaller
            color={Colors.PrimaryOne}>

              {props.errorMessage}
          </PSmaller>

        </ErrorMessage>
      }

    </Container>
  );
};