import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  FontSizes,
  Theme
} from 'common';

import {
  Button,
} from './Button';

import {
  Text
} from './text';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Space = styled.div`
  width: ${props => props.width || '5px'};
`;

export const NumericSelect = props => {

  const [counter, setCounter] = React.useState(props.defaultValue || 0);

  const onPlusClick = () => {

    const c = counter + 1;

    if (c > (props.max ?? 99999)) {
      return;
    }

    setCounter(c);

    props.onCounterChange && props.onCounterChange(c);
  };

  const onMinusClick = () => {

    if (!counter) {
      return;
    }

    const c = counter - 1;

    setCounter(c);

    props.onCounterChange && props.onCounterChange(c);
  };

  return (

    <Container>

      { !!counter &&

        <>

          <Text fontSize={FontSizes.Small} whiteSpace={'nowrap'}>{`X ${counter}`}</Text>

          <Space width={'8px'}/>

          <Button
            backgroundColor={Theme.Button.Secondary.Background}
            height={'30px'}
            width={'30px'}
            onClick={onMinusClick}>
            -
          </Button>

          <Space/>

        </>
      }

      <Button
        disabled={props.disabled}
        height={'30px'}
        width={props.addButtonWidth || '30px'}
        onClick={onPlusClick}
      >{props.addButtonText || '+'}</Button>

    </Container>
  );
};