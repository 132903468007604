import React from 'react';
import styled from 'styled-components';
import { MediaQueries, FontSizes, Theme } from 'common';
import { Button, Text } from 'common/components';

const CardContainer = styled.div`
  background-color: ${Theme.Card.Background};
  border: ${Theme.Card.Border};
  border-radius: ${Theme.Card.BorderRadius};
  padding: 16px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  box-shadow: ${Theme.Card.BoxShadow};

  @media only screen and ${MediaQueries.Md} {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  margin-right: 0;

  @media only screen and ${MediaQueries.Md} {
    margin-right: 16px;
  }
`;

const EventImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 4px;

  @media only screen and ${MediaQueries.Md} {
    width: 370px;
    height: 100%;
  }
`;

const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const EventTitle = styled.h3`
  margin: 10px 0 0 0;
  font-size: 1.1em;

  @media only screen and ${MediaQueries.Md} {
    margin: 0;
  }
`;

const EventDate = styled.p`
  margin: 8px 0 2px 0;
  color: #666;
  font-size: ${FontSizes.Small};
`;

const TicketCount = styled.p`
  margin: 6px 2px 10px 0;
  font-weight: bold;
  font-size: ${FontSizes.Smaller};
  text-align: left;

  @media only screen and ${MediaQueries.Md} {
    text-align: right;
    margin: 6px 2px;
  }
`;

const EventLocation = styled.p`
  margin: 4px 0;
  color: #666;
  font-size: 0.9em;
`;

const TicketCard = ({ ticket, onDownload }) => {
  return (
    <CardContainer>
      <ImageContainer>
        <EventImage src={ticket.cover_media_url} alt={ticket.name} />
      </ImageContainer>
      <DetailsContainer>
        <EventTitle>{ticket.name}</EventTitle>
        { !ticket.displayDate?.startsWith('Invalid date') && <EventDate>{ticket.displayDate}</EventDate> }
        <EventDate>{ticket.location_display}</EventDate>
        <EventLocation>
          <Text textDecoration={'underline'} onClick={() => window.open(ticket.map_link, '_blank')} fontSize={FontSizes.Small}>{ticket.address}</Text>
        </EventLocation>
        <div style={{ flex: 1 }} />
        <TicketCount>Order #{ticket.id} - {ticket.ticket_count} {ticket.ticket_count === 1 ? 'Ticket' : 'Tickets'} Purchased</TicketCount>
        <Button width={'100%'} text="Download Tickets" onClick={() => onDownload(ticket.id)} />
      </DetailsContainer>
    </CardContainer>
  );
};

export default TicketCard;