import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  FontSizes,
  Logger,
  MediaQueries,
  Theme,
  isValidProviderResult,
} from 'common';

import {
  Button,
  H3,
  PSmall,
  Markdown,
} from 'common/components';

import {
  Calendar,
  Pin
} from 'common/components/svg';

import {
  withCommon,
} from 'common/hocs';

import {
  EventDetailsReserveTickets
} from 'ui/event/components';

import {
  ActionContainer,
  Container,
  Content,
  DetailContainer,
  DetailContent,
  DetailContentText,
  DetailsText,
  Header,
  MainContentContainer,
  MainImage,
  MobileContentSpace,
  SideContentContainer,
  SideContent
} from 'ui/event/styled';

import {
  EventProvider,
} from 'providers';

const MobileHiddenImage = styled.img`
  width: 100%;
  height: auto;
  opacity: 0;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

const EventDetailsUI = props => {

  const [state, setState] = React.useState({
    event: {}
  });

  const load = () => {

    if (!props.common?.pathParams?.id) {

      Logger.error('EventDetails', 'load', 'No eventId found');
      props.common.showToast('A technical error occurred');
      return;
    }

    EventProvider.getEventById(props.common.pathParams.id)
      .then(ret => {

        props.common.hideLoading();

        if (!isValidProviderResult(ret)) {

          props.common.showToast('Oh no, we weren\'t able to load your event details, please try again.');
          return;
        }

        setState(prevState => ({
          ...prevState,
          event: ret.data
        }));
      })
      .catch(e => {

        // TODO: show error
        Logger.error('EventDetails', 'load', e);
        props.common.showToast('Oh no, we weren\'t able to load your event details, please try again.');

        props.common.hideLoading();
      });
  };

  React.useEffect(load, []); // eslint-disable-line

  const displayDateSplit = state.event.is_multi_event
    ? [state.event.displayDate]
    : (state.event.displayDate ?? '').split(' - ');

  return (

    <Container>

      <MainContentContainer boxShadow={Theme.EventDetails.ContentBoxShadow}>
 
        <MainImage isMobileFixed={!props.common.isWidget} src={state.event.cover_media_url}/>

        { !props.common.isWidget && <MobileHiddenImage src={state.event.cover_media_url}/> }

        <Content
          backgroundColor={Theme.EventDetails.ContentBackground}
          desktopBackgroundColor={Theme.EventDetails.ContentBackground}>

          <Header>{state.event.name}</Header>

          <DetailContainer>

            <Pin height={25} width={22} />

            <DetailContent>
              
              <DetailContentText padding={'0 0 5px 0'} bold>{state.event.location_display}</DetailContentText>
              <DetailContentText onClick={() => window.open(state.event.map_link, '_blank')} fontSize={FontSizes.Small}>{state.event.address}</DetailContentText>
              
            </DetailContent>

          </DetailContainer>
          <DetailContainer>

            <Calendar height={25} width={22} />

            <DetailContent>
              
              <DetailContentText padding={'0 0 5px 0'} bold>{displayDateSplit[0]}</DetailContentText>
              <DetailContentText fontSize={FontSizes.Small}>{state.event.is_multi_event ? 'Time as per each event' : (state.event.displayTime || '-')}</DetailContentText>
              
            </DetailContent>

          </DetailContainer>
          <div style={{ height: '1px', width: '100%', backgroundColor: Theme.Divider.Color }} />
          <DetailsText>

            <div style={{ height: '1rem' }} />

            <Markdown markdown={state.event.description}/>

            { state.event.id < 19 &&
                <>
                <H3 margin={'1.6rem 0 1rem 0'}>Information</H3>

                <PSmall
                  display={'block'}
                  whiteSpace={'pre-line'}
                  padding={'0 0 1.6rem 0'}>
                    You are not allowed to bring weapons, food, or drinks to the event.
                </PSmall>
                </>
            }

            <MobileContentSpace
              height={'80px'}
            />

          </DetailsText>

        </Content>

        <ActionContainer>
          
          <DetailContent
            padding={'0'}>

            <DetailContentText fontSize={FontSizes.Small}>Price:</DetailContentText>
            <DetailContentText fontSize={'1.2rem'} bold>{state.event.priceDisplay}</DetailContentText>

          </DetailContent>

          <Button onClick={() => props.common.navigate(`/event/ticket/select/${state.event.id}`)}>Buy Tickets</Button>

        </ActionContainer>

      </MainContentContainer>

      <SideContentContainer margin={'0 15px 0 20px'}>

        <SideContent top={'0'} sticky={false}>

          <EventDetailsReserveTickets
            tickets={state.event.prices || []}
            onBuyTicketsClick={() => props.common.navigate(`/event/ticket/select/${state.event.id}`)}
          />

        </SideContent>

      </SideContentContainer>

    </Container>
  );
};

export const EventDetails = withCommon(EventDetailsUI);