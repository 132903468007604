import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactGA from 'react-ga4';
//import reportWebVitals from './reportWebVitals';

import {
  DataStore,
  Environment
} from 'common';

const traceId = DataStore.get("TRACE_ID");

if (!traceId) {
  DataStore.set("TRACE_ID", crypto ? crypto.randomUUID() : new Date().getTime());
}

const rootElement = document.getElementById('root');
if (rootElement) {

  if (Environment.name === 'Production') {
    ReactGA.initialize('G-XDPFGEDP9P');
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
}

window.initWidget = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    ReactDOM.render(
      <React.StrictMode>
        <App widget={true} />
      </React.StrictMode>,
      element
    );
  }
};

serviceWorkerRegistration.unregister();
//reportWebVitals();