import
  React, { useRef }
from 'react';

import
  styled
from 'styled-components/macro';

import
  PaystackPop
from '@paystack/inline-js';

import {
  DataStore,
  FontFamilies,
  FontSizes,
  Image,
  MediaQueries,
  Logger,
  Theme,
  isValidProviderResult
} from 'common';

import {
  Button,
  H2,
  Markdown,
  NumericSelect,
  Stepper,
  Text,
  PSmall,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  EventMobileTicketSummary
} from 'ui/event/components';

import {
  EventName,
  FullWidthContainer,
  MaxWidthContainer,
  StepperContainer,
} from 'ui/event/styled';

import {
  OrderProvider,
  PaymentProvider
} from 'providers';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
`;

const SummaryContainer = styled.div`
  background-color: ${Theme.Card.Background};
  border: ${Theme.Card.Border};
  box-shadow: ${Theme.Card.BoxShadow};
  border-radius: ${Theme.Card.BorderRadius};
  overflow: hidden;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.padding || '20px'};
`;

const Information = styled.div`
  color: ${Theme.Text.Color};
  font-family: ${FontFamilies.Bold};
  padding: ${props => props.padding || `18px 20px 5px 20px`};
  font-size: ${FontSizes.Regular};

  @media only screen and ${MediaQueries.Md} {
    font-size: 1.1rem;
  }
`;

const Header = styled.div`
  color: ${Theme.Text.Color};
  font-family: ${FontFamilies.Bold};
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;

  @media only screen and ${MediaQueries.Md} {
    font-size: ${FontSizes.Bigger};
    padding: 20px 20px 15px 20px;
  }
`;

const Divide = styled.div`
  background-color: ${Theme.Divider.Color};
  height: 1px;
`;

const EventPaymentUI = props => {

  const [showNoStock, setShowNoStock] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(20 * 60); // 20 minutes
  const [paymentLogo, setPaymentLogo] = React.useState('');
  const transactionIdRef = useRef(null);
  const popupRef = useRef(null);
  const paymentSuccessRef = useRef(false);
  const orderIdRef = useRef(null);

  const [state, setState] = React.useState({
    event: DataStore.get('selectedEvent') || {},
    charityPrices: (DataStore.get('selectedEvent') || {})?.prices?.filter(p => p.type === 'charity') || [],
    selectedTickets: DataStore.get('selectedTickets')
  });

  const onCharityCounterChange = (count, priceId) => {

    const price = state.charityPrices.find(cp => cp.id === priceId);

    if (!price) {
      return;
    }

    const sTickets = state.selectedTickets;

    sTickets.selectedTickets[priceId] = {
      ...price,
      count: count,
      totalPrice: count * price.price,
    };

    let totalPrice = 0;
    let totalTickets = 0;

    for (const item of Object.values(sTickets.selectedTickets)) {

      totalPrice += item.totalPrice;
      totalTickets += item.count;
    }

    for (const item of Object.values(sTickets.selectedMerch)) {

      totalPrice += item.totalPrice;
      totalTickets += item.count;
    }

    sTickets.price = totalPrice;
    sTickets.tickets = totalTickets;

    setState(prev => ({
      ...prev,
      selectedTickets: sTickets
    }));
  };

  const onPayClick = async () => {

    try {
      props.common.showLoading('Placing your order...');

      const selectedTickets = state.selectedTickets;
      const selectedEvent = DataStore.get('selectedEvent');

      if (!selectedTickets?.selectedTickets) {
        throw Error('No tickets selected');
      }

      const tickets = [];

      for (const ticket of Object.values(selectedTickets.selectedTickets || {})) {

        tickets.push({
          event_price_id: ticket.id,
          quantity: ticket.count
        });
      }

      const merchList = [];

      for (const merch of Object.values(selectedTickets.selectedMerch || {})) {

        merchList.push({
          product_variant_id: merch.product_variant_id,
          quantity: merch.count
        });
      }

      const orderRes = await OrderProvider.createOrder(selectedEvent.id, tickets, merchList, selectedEvent.custom_fields);

      if (!isValidProviderResult(orderRes)) {
        throw Error(orderRes.message || 'Unable to create order');
      }

      if (orderRes.data?.message === 'NO_MORE_STOCK') {

        props.common.hideLoading();
        Logger.error('EventPayment', 'onPayClick', 'No more stock');
        setShowNoStock(true);
        return;
      }

      if (orderRes.data?.message === 'NO_MORE_MERCH_STOCK') {

        props.common.hideLoading();
        setShowNoStock(true);
        return;
      }

      if (!orderRes.data.orderId) {
        throw Error(orderRes.message || 'Unable to create order');
      }

      orderIdRef.current = orderRes.data.orderId;

      if (orderRes.data.skip_payment) {

        props.common.navigate(`/payment-result/success?order_id=WT-${orderRes.data.orderId}`);
        return;
      }

      if (orderRes.data.platform === 'bolandrugby' || window.location.href.includes('bolandrugby') || window.location.href.includes('forestwalk')) {
        await createYocoPayment(orderRes.data.orderId);
      }
      else {
        await createPayment(orderRes.data.orderId);
      }
    }
    catch (ex) {

      Logger.error('EventPayment', 'onPayClick', ex);
      props.common.showToast('Oops! Something didn\'t go as planned. Please try again.');
      props.common.hideLoading();
    }
  };

  const createPayment = async (orderId) => {

    try {

      props.common.showLoading('Preparing your payment...');

      const paymentRes = await PaymentProvider.checkout(orderId);

      if (!isValidProviderResult(paymentRes) || !paymentRes.data.access_code) {
        throw Error(paymentRes.message || 'Unable to create payment');
      }

      const popup = new PaystackPop();
      popupRef.current = popup;
      props.common.hideLoading();

      popup.resumeTransaction(paymentRes.data.access_code, {
        onLoad: (transaction) => {
          transactionIdRef.current = transaction.id;
        },
        onSuccess: (transaction) => {

          paymentSuccessRef.current = true;
          const url = new URL(transaction.redirecturl);
          const queryParams = url.search;
          props.common.navigate(`/payment-result/success${queryParams}`);
        },
        onError: (error) => {
          Logger.error('EventPayment', 'createPayment', error);
          props.common.showToast('Oops! Something didn\'t go as planned. Please try again.');
          props.common.hideLoading();
        },
        onCancel: () => {
          OrderProvider.cancelOrder(orderIdRef.current)
            .catch(e => Logger.error('EventPayment', 'cancelOrder - Popup cancelled', e));
        }
      });
    }
    catch(e) {
      Logger.error('EventPayment', 'createPayment', e);
      throw e;
    }
  };

  const createYocoPayment = async (orderId) => {

    const timeout = setTimeout(() => {
      props.common.showLoading('Redirecting...');
    }, 4000);

    try {
      props.common.showLoading('Preparing your payment...');

      let currentUrl;

      if (props.common.isWidget) {
        currentUrl = `${window.location.origin}${window.location.pathname}#`;
      }

      const paymentRes = await PaymentProvider.checkout(orderId, currentUrl);

      if (!isValidProviderResult(paymentRes) || !paymentRes.data.redirect_url) {
        throw Error(paymentRes.message || 'Unable to create payment');
      }

      window.location.href = paymentRes.data.redirect_url;
    }
    catch(e) {
      Logger.error('EventPayment', 'createPayment', e);
      throw e;
    }
    finally {
      clearTimeout(timeout);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  React.useEffect(() => {

    if (timeLeft === 0) {
      props.common.navigate('/');
      return; // Stop the timer when it reaches 0
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [timeLeft]); // eslint-disable-line

  React.useEffect(() => {

    PaymentProvider.getPaymentLogo()
      .then(ret => {

        if (ret.ok && ret.data?.payment_image) {
          setPaymentLogo(ret.data.payment_image);
        }
      })
      .catch(e => Logger.error('EventPayment', 'getPaymentLogo', e))

    const searchParams = new URLSearchParams(props.common.location.search);
    const retry = searchParams.get('retry');
    const orderId = searchParams.get('orderId');

    if (retry && orderId) {

      props.common.showLoading();
      createYocoPayment(parseInt(orderId));
    }

    if (!DataStore.get('selectedTickets') || !DataStore.get('selectedEvent')) {
      props.common.navigate('/');
    }

    return () => {
      if (popupRef.current && transactionIdRef.current && !paymentSuccessRef.current) {
        popupRef.current.cancelTransaction(transactionIdRef.current);

        OrderProvider.cancelOrder(orderIdRef.current)
          .catch(e => Logger.error('EventPayment', 'cancelOrder', e));
      }
    };
  }, []); // eslint-disable-line

  if (!DataStore.get('selectedTickets') || !DataStore.get('selectedEvent')) {
    return <></>
  }
  
  return (
    <>

      { !showNoStock &&
      
        <>
        <Text
          maxWidth={'1100px'}
          margin={'0 auto'}
          width={'100%'}
          display={'block'} 
          padding={'20px 15px 0 15px'} 
          fontSize={FontSizes.Small}
          color={Theme.Text.Color}
        >
          <span style={{cursor: 'pointer'}} onClick={() => props.common.navigate(-1)}>← Back</span>
        </Text>

        <EventName>{state.event.name}</EventName>

        <Text display={'block'} padding={'0 0 5px 0'} textAlign={'center'} fontSize={FontSizes.Small}>{state.event.location_display}</Text>

        <Text display={'block'} padding={'0 0 20px 0'} textAlign={'center'} fontSize={FontSizes.Small}>{state.event.displayDate}</Text>

        <FullWidthContainer>

          <StepperContainer>

            <Stepper
              items={[{text: 'ORDER'}, {text: 'DETAILS'}, {text: 'PAYMENT'}]}
              selectedIndex={2}
            />

          </StepperContainer>

          <MaxWidthContainer padding={'0 15px 15px 15px'}>

            <CenterContainer
              padding={'5px 20px 20px 20px'}>

                <PSmall padding={'0 0 3px 0'}>Almost done! Final step...</PSmall>
                <Text fontSize={FontSizes.Big} fontFamily={FontFamilies.Bold}>{formatTime(timeLeft)}</Text>
            </CenterContainer>

            { Array.isArray(state.charityPrices) && state.charityPrices.length > 0 &&
              <>
                <SummaryContainer>
                  <Header>Donate to Charity?</Header>
                  <Divide/>
                  { state.charityPrices.map((cp, charityIndex) => (

                    <div key={`charity-${charityIndex}`} style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '18px 20px 15px 20px' }}>
                        <Information
                          padding={'00'}>
                          {cp.title}
                        </Information>
                        <NumericSelect
                          addButtonWidth={'70px'}
                          addButtonText={`Add R${cp.price}`}
                          max={cp.available_tickets}
                          onCounterChange={(count) => onCharityCounterChange(count, cp.id)}
                        />
                      </div>
                      <Markdown
                        defaultFontSize={FontSizes.Smaller}
                        padding={'0 20px 20px 20px'}
                        gap={'15px'}
                        markdown={cp.description}
                      />
                    </div>
                  ))}
                </SummaryContainer>
                <div style={{ height: '15px' }} />
              </>
            }

            <SummaryContainer>

              <EventMobileTicketSummary
                totals={state.selectedTickets}
                hideCheckoutButton={true}>

                <Button
                  text={state.selectedTickets?.price === 0 ? 'Claim Order' :'Pay with card'}
                  width={'100%'}
                  onClick={onPayClick}
                />
              </EventMobileTicketSummary>

            </SummaryContainer>

            <div style={{ height: '15px' }} />

            { state.event.id < 19 &&

              <SummaryContainer>

                <Information>
                    Information
                </Information>

                <Text
                  fontSize={FontSizes.Small}
                  padding={'0 20px 20px 20px'}>
                    You are not allowed to bring weapons, food, or drinks to the event.
                </Text>

              </SummaryContainer>
            }

            { paymentLogo && 
            
              <CenterContainer>
                {window.location.hostname.includes('bolandrugby') && <PSmall padding={'0 0 2px 0'}>Payments processed by</PSmall>}
                {!window.location.hostname.includes('bolandrugby') && <div style={{ height: '10px' }} />}

                <Image
                  alt={'payment processor'}
                  width={'160px'}
                  height={'auto'}
                  src={paymentLogo}
                />
              </CenterContainer>
            }

          </MaxWidthContainer>

        </FullWidthContainer>
        </>
      }

      { showNoStock &&

        <Container>
          <H2>Tickets Sold Out</H2>
          <Text padding={'0 15px'} textAlign={'center'}>Looks like the tickets were reserved while you were in the process.</Text>
          <Text padding={'10px 15px 25px 30px'} textAlign={'center'}>Thanks for your interest, and sorry for the inconvenience!</Text>        
          <Button onClick={() => props.common.navigate('/')}>Home</Button>
        </Container>
      }

    </>
  );
};

export const EventPayment = withCommon(EventPaymentUI, {
  showLoadingOnLoad: false
});