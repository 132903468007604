export function resolveResponse(res) {

  if (!res) {

    return {
      ok: false,
      message: 'Invalid response',
      data: null,
    }
  }

  let ok = res.status >= 200 && res.status < 300;

  if (ok && res.data && typeof res.data.isSuccess === 'boolean') {
    ok = res.data.isSuccess;
  }

  return {
    ok: ok,
    message: (res.data && res.data.message) || '',
    data: (res.data && res.data.data) || res.data || {}
  }
}

export function resolveRejected(res) {

  let err;

  if (
    res.response
    && res.response.data
    && res.response.data.message
  ) {
    err = res.response.data.message;
  }

  return {
    ok: false,
    message: err || 'Unfortunately a technical error occurred',
    data: res.response && res.response.data,
  };
}

export const initGoogleMaps = (callback) => {

  if (window.google) {

    typeof callback === 'function' && callback();
    return;
  }

  window.initMap = () => {
    typeof callback === 'function' && callback();
  };

  const script = document.createElement('script');
  script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDzQEQE8-0GNVHCwroyFJO2uAQvOaZ7z18&callback=initMap&libraries=places&v=weekly';
  document.body.appendChild(script);
};

export const updateThemeColor = (color) => {
  const metaThemeColor = document.querySelector('meta#theme-color');
  if (metaThemeColor) {
    metaThemeColor.setAttribute('content', color);
  }
};
