import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  DeviceInfo,
  FontFamilies,
  Images,
  Image,
  MediaQueries,
  Theme,
} from 'common';

import {
  Button
} from 'common/components';

import {
  WeTicketLogo
} from 'common/components/svg';

import {
  Arrow,
} from './Arrow';

import {
  UserMenu,
} from './UserMenu';

const Container = styled.div`
  background-color: ${Theme.Header.Background};
  border-bottom: ${Theme.Header.Border};
  box-shadow: ${Theme.Header.BoxShadow};
  height: ${props => props.isScrolled ? '70px' : '100px'};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s ease;
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const HeaderSpacer = styled.div`
  height: ${props => props.isScrolled ? '70px' : '100px'};
  transition: height 0.3s ease;
`;

const Fade = styled.div`
  background-color: ${Colors.Black};
  box-shadow: 0px 5px 30px 30px ${Colors.Black};
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

const LogoContainer = styled.div`
  max-width: 1500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const LogoImage = styled.img`
  height: 45px;
  width: auto;
  cursor: pointer;
`;

const ProfileContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  width: 45px;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;

const UserMenuContainer = styled.div`
  position: fixed;
  left: ${props => props.left || '100%'};
  bottom: 0;
  top: 0;
  
  ${DeviceInfo.IsMobile
    ? `width: 100%;`
    : `width: 300px;`}

  transition: left .4s ease-out;
  z-index: 5;
`;

const BurgerButton = styled.div`
  display: ${props => props.showBurger ? 'block' : 'none'};
  cursor: pointer;
  padding: 10px;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }

  div {
    width: 25px;
    height: 3px;
    background-color: ${Theme.Text.Color};
    margin: 4px 0;
    transition: 0.4s;

    &:nth-child(1) {
      transform: ${props => props.isOpen ? 'rotate(-45deg) translate(-5px, 5px)' : 'none'};
    }
    &:nth-child(2) {
      opacity: ${props => props.isOpen ? '0' : '1'};
    }
    &:nth-child(3) {
      transform: ${props => props.isOpen ? 'rotate(45deg) translate(-5px, -5px)' : 'none'};
    }
  }
`;

const MobileMenu = styled.div`
  display: block;
  position: fixed;
  top: ${props => props.isOpen && props.isScrolled
    ? '70px'
    : props.isOpen && !props.isScrolled
      ? '100px'
      : '-200px'};
  left: 0;
  right: 0;
  background-color: ${Theme.Header.Background};
  padding: 20px;
  transition: top 0.3s ease-in-out;
  z-index: 3;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

const ViewYourTicketsButton = styled.div`
  display: none;

  @media only screen and ${MediaQueries.Md} {
    display: block;
  }
`;

const ArrowContainer = styled.div``;

export const Header = (props) => {

  const [showMenu, showMenuSetState] = React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {

    if (!props.transitionHeader) {
      return;
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [props.transitionHeader]);

  const onMenuToggle = () => {
    showMenuSetState(!showMenu);
  };

  const desktopLeft = window.innerWidth - 300;

  const menuLeft = showMenu
    ? (DeviceInfo.IsMobile ? '0' : `${desktopLeft}px`)
    : '100%';

  return (
    <>

    <Container isScrolled={!props.transitionHeader || isScrolled}>

      { !props.isBackgroundTransparent && false &&

        <Fade/>
      }

      { false && <LogoImage
          src={Images.Logo}
          onClick={() => props.onLogoClick && props.onLogoClick()}
        />
      }

      <LogoContainer>

        <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => props.onLogoClick && props.onLogoClick()}>
          <WeTicketLogo
            height={45} 
            width={210}
          />
        </div>

        { props.showHeaderYourTickets &&
          
          <>
            <ViewYourTicketsButton>
              <Button
                fontFamily={FontFamilies.Bold}
                text={'VIEW YOUR TICKETS'}
                width={'185px'}
                onClick={() => {
                  if (DataStore.get('LOGGED_IN_EMAIL') && DataStore.get('ACCESS_TOKEN')) {
                    props.navigate('/profile/view-my-tickets');
                  } else {
                    props.navigate('/identity/otp-login');
                  }
                }}
              />
            </ViewYourTicketsButton>

            <BurgerButton 
              showBurger={props.showHeaderYourTickets}
              isOpen={showMenu} 
              onClick={onMenuToggle}
            >
              <div/>
              <div/>
              <div/>
            </BurgerButton>

            <MobileMenu 
              isOpen={showMenu}
              isScrolled={!props.transitionHeader || isScrolled}
            >
              <Button
                fontFamily={FontFamilies.Bold}
                text={'VIEW YOUR TICKETS'}
                width={'100%'}
                onClick={() => {
                  if (DataStore.get('LOGGED_IN_EMAIL') && DataStore.get('ACCESS_TOKEN')) {
                    props.navigate('/profile/view-my-tickets');
                  } else {
                    props.navigate('/identity/otp-login');
                  }
                }}
              />
            </MobileMenu> 
          </>
        }

      </LogoContainer>

      { props.showProfileMenu &&
        
        <React.Fragment>

          <ProfileContainer
            onClick={onMenuToggle}>

            <Image
              height={'100%'}
              src={Images.Icons.UserIcon}
            />

            <ArrowContainer>

              <Arrow
                direction={'down'}
                size={'8px'}
                active={true}
              />

            </ArrowContainer>

          </ProfileContainer>

          <UserMenuContainer
            left={menuLeft}>

            <UserMenu
              showClose={true}
              onCloseClick={onMenuToggle}
            />

          </UserMenuContainer>

        </React.Fragment>
      }

      </Container>

      <HeaderSpacer isScrolled={!props.transitionHeader || isScrolled}/>

    </>
  );
};