let envConfig;

if (window.location.hostname.includes('weticket-prod.netlify.app') || window.location.hostname.includes('weticket.co.za') || process.env.ENV === 'PROD') {

  envConfig = {
    name: 'Production',
    uiHost: 'https://weticket.co.za/',
    apiHost: 'https://api.weticket.co.za/',
    assetBase: 'https://weticket.co.za/assets',
  };
}
else if (process.env.ENV === 'QA') {

  envConfig = {
    name: 'QA',
    uiHost: 'https://weticket.cypress-solutions.co.za/',
    apiHost: 'https://weticket-api-dev-791836476647.us-central1.run.app/',
    assetBase: 'https://weticket.cypress-solutions.co.za/assets',
  };
}
else {

  envConfig = {
    name: 'Development',
    uiHost: 'http://localhost:3000/',
    apiHost: 'http://localhost:8080/',
    assetBase: 'http://localhost:3000/assets',
  };
}

export const Environment = envConfig;