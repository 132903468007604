import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  Images,
  Theme
} from 'common';

const Container = styled.div`
  display: flex;
  padding: ${props => props.padding || '0'};
  align-items: center;
`;

const Check = styled.div`
  display: inline-block;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  height: 28px;
  width: 28px;
  min-width: 28px;
  background-color: ${props => props.isChecked ? Colors.PrimaryOne : props.backgroundColor || Colors.Black};
  background-image: url(${Images.Icons.Tick});
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: ${props => props.isChecked ? 1 : 0.25};

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;

const Label = styled.div`
  color: ${Theme.Text.Color};
  display: flex;
  align-items: center;
  font-size: ${FontSizes.Small};
  padding: 0 0 0 10px;
  cursor: pointer;
`;

export const Checkbox = props => {

  const [isChecked, isCheckedSetState] = React.useState(props.defaultChecked);

  const onClick = () => {

    typeof props.onClick === 'function' && props.onClick(!isChecked);
    isCheckedSetState(!isChecked);
  };

  return (

    <Container
      padding={props.padding}>

      <Check
        isChecked={isChecked}
        onClick={onClick}
        backgroundColor={props.backgroundColor}
      />

      <Label
        onClick={onClick}>

          {props.label}
      </Label>

    </Container>
  );
};