import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Theme,
  FontFamilies,
  FontSizes,
} from 'common';

import {
  Text,
  Button
} from 'common/components';

const Container = styled.div`
  width: 100%;
`;

const Divide = styled.div`
  background-color: ${Theme.Divider.Color};
  height: 1px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  padding: 20px;
`;

export const EventDetailsReserveTickets = props => {

  const ticketComponents = [];

  for (const ticket of props.tickets || []) {

    if (ticket.type === 'charity') {
      continue;
    }

    ticketComponents.push(
      <React.Fragment key={ticket.id}>

        <ContentContainer>

          <div>

            <Text
              display={'block'}
              fontSize={FontSizes.Regular}
              padding={'15px 20px 2px 20px'}>
                {ticket.title}
                
                { ticket.available_tickets <= 0 ?

                  <b> (Sold Out)</b>

                  : ticket.available_tickets <= 10

                    ? <b> (Only {ticket.available_tickets} left)</b>

                    : <></>
                }
            </Text>

            <Text
              display={'block'}
              fontSize={FontSizes.Smaller}
              padding={'0 20px 15px 20px'}>{ticket.description}</Text>

          </div>

          <Text
            fontFamily={FontFamilies.Bold}
            padding={'0 20px 0 0'}>R{ticket.price}</Text>

        </ContentContainer>

        <Divide/>

      </React.Fragment>
    );
  }

  return (

    <Container>

      <Text
        fontSize={FontSizes.Bigger}
        fontFamily={FontFamilies.Bold}
        padding={'20px 20px 15px 20px'}>
          Reserve Tickets
      </Text>

      <Divide/>

      {ticketComponents}

      <ButtonContainer>

        <Button
          width={'100%'}
          onClick={() => props.onBuyTicketsClick && props.onBuyTicketsClick()}>
            Buy Tickets
        </Button>

      </ButtonContainer>

    </Container>
  );
};