import { Theme } from "common";

export const WeTicketShortLogo = ({ height = 130, width = 127, color = Theme.Svg.ColorDefault }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 127 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2507_858)">
      <path d="M13.75 42.7331L15.2076 40.7676H23.8077L31.8975 81.5355H32.6992L41.3721 45.5723H51.7213L60.3942 81.5355H61.1959L69.2858 40.7676H77.8858L79.3435 42.7331L69.5044 90.9994H52.8145L46.5467 64.4275L40.2789 90.9994H23.589L13.75 42.7331Z" fill={color}/>
      <path d="M87.3672 90.9994V40.7676H121.767V50.2315H97.8622V60.4235H119.581V69.8875H97.8622V81.5355H121.767V90.9994H87.3672Z" fill={color}/>
      <path d="M123.215 81.7699C125.632 82.467 127.041 84.9964 126.176 87.356C121.309 100.63 112.222 112.001 100.245 119.687C86.9877 128.195 71.0896 131.608 55.5029 129.293C39.9162 126.978 25.7 119.092 15.4936 107.1C5.2873 95.1072 -0.215655 79.8225 0.00647115 64.0838C0.228597 48.345 6.16071 33.2214 16.7014 21.5209C27.2421 9.82043 41.6753 2.33805 57.3211 0.462991C72.9669 -1.41207 88.7624 2.44759 101.775 11.3254C113.53 19.3451 122.292 30.9675 126.782 44.3734C127.581 46.7564 126.101 49.2452 123.665 49.874C121.23 50.5027 118.763 49.0318 117.937 46.6581C114.017 35.3836 106.569 25.6162 96.6366 18.8398C85.4459 11.2049 71.8618 7.88562 58.4063 9.49817C44.9509 11.1107 32.5384 17.5456 23.4734 27.608C14.4084 37.6704 9.30679 50.6767 9.11577 64.212C8.92474 77.7474 13.6573 90.8922 22.4347 101.206C31.2122 111.519 43.4381 118.301 56.8427 120.292C70.2472 122.283 83.9196 119.347 95.3213 112.031C105.441 105.537 113.162 95.9827 117.399 84.8232C118.291 82.4736 120.798 81.0727 123.215 81.7699Z" fill={color}/>
      </g>
      <defs>
      <clipPath id="clip0_2507_858">
      <rect width="127" height="130" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};