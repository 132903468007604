import {
  Modal
} from 'common/components';

export const HtmlModal = ({ url, show, onClose }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <iframe title={'HTML Modal'} style={{
        width: '80vw',
        height: '62vh',
        margin: '0 auto',
        border: 0,
      }} src={url}/>
    </Modal>
  );
};
