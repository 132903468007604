import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  Theme,
  MediaQueries,
} from 'common';

export const Container = styled.div`
  display: flex;
  ${props => props.padding && `padding: ${props.padding};`}

  @media only screen and ${MediaQueries.Md} {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    flex: 1;
  }
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}
`;

export const Content = styled.div`
  flex: 1;
  background-color: ${props => props.backgroundColor || Colors.LightGreyBrown};
  position: relative;
  min-height: 100vh;

  @media only screen and ${MediaQueries.Md} {
    min-height: auto;
    background-color: ${props => props.desktopBackgroundColor || Colors.LightGreyBrown};
  }
`;

export const MobileContentSpace = styled.div`
  height: ${props => props.height || '40vh'};
  position: relative;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

export const SideContentContainer = styled.div`
  display: none;
    
  @media only screen and ${MediaQueries.Md} {
    min-width: 390px;
    height: 100%;
    display: block;
    margin: ${props => props.margin || '0 15px 0 5px'};
  }
`;

export const SideContent = styled.div`
  display: none;
  
  @media only screen and ${MediaQueries.Md} {
    background-color: ${Theme.Card.Background};
    display: block;
    border: ${Theme.Card.Border};
    border-radius: ${Theme.Card.BorderRadius};
    box-shadow: ${Theme.Card.BoxShadow};
    margin: ${props => props.marginTop || '50px'} 0 0 0;
    position: ${props => props.sticky ? 'sticky' : 'relative'};
    top: ${props => props.top || '50px'};
    overflow: hidden;
    bottom: 0;
    ${props => props.padding && `padding: ${props.padding};`}
  }
`;

export const Header = styled.div`
  background-color: ${Theme.EventDetails.HeaderBackground};
  color: ${Theme.Text.Color};
  font-family: ${FontFamilies.Bold};
  font-size: 1.35rem;
  padding: 12px 15px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  position: relative;
`;

export const MainImage = styled.img`
  width: 100%;
  height: auto;
  position: ${props => props.isMobileFixed ? 'fixed' : 'relative'};
  top: ${props => props.isMobileFixed ? '70px' : '0'};

  @media only screen and ${MediaQueries.Md} {
    position: relative;
    top: 0;
  }
`;

export const DetailsText = styled.div`
  padding: 5px 20px 20px 20px;
`;

export const DetailContainer = styled.div`
  background-color: ${props => props.backgroundColor || Theme.Card.Background};
  border-top: 1px solid ${props => props.borderColor || Theme.Divider.Color};
  display: flex;
  align-items: center;
  padding: ${props => props.padding || '10px 20px'};
  position: relative;
  width: 100%;
`;

export const DetailIcon = styled.div`
  height: 25px;
  width: 22px;
`;

export const DetailContent = styled.div`
  color: ${Theme.Text.Color};
  padding: ${props => props.padding || '0 0 0 15px'};
  width: ${props => props.width || '100%'};
`;

export const DetailContentText = styled.div`
  font-size: ${props => props.fontSize || FontSizes.Regular};
  padding: ${props => props.padding || '0'};
  
  ${props => props.onClick && 'text-decoration: underline; cursor: pointer;'}
  ${props => props.bold && `font-family: ${FontFamilies.Bold};`}
`;

export const ActionContainer = styled.div`
  background-color: ${Theme.EventDetails.ActionContainerBackground};
  border-top: 1px solid ${Theme.EventDetails.ActionContainerBorder};
  box-shadow: ${Theme.EventDetails.ActionContainerBoxShadow};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

export const FullWidthContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StepperContainer = styled.div`
  padding: 0 20px 50px 20px;
  max-width: 650px;
  width: 100%;

  @media only screen and ${MediaQueries.Md} {
    width: 50vw;
  }
`;

export const MaxWidthContainer = styled.div`
  padding: ${props => props.padding || '0 20px 20px 20px'};
  max-width: 650px;
  width: 100%;

  @media only screen and ${MediaQueries.Md} {
    width: 50vw;
  }
`;

export const EventName = styled.div`
  color: ${Theme.Text.Color};
  font-size: ${FontSizes.Bigger};
  font-family: ${FontFamilies.Bold};
  padding: 20px 15px 6px 15px;
  text-align: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
`;
