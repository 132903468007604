/*import {
  Login,
  Register,
  EmailConfirm,
  EmailVerified,
} from 'ui/identity';

export const IdentityRoutes = {
  login: {
    component: Login,
    path: '/identity/login',
  },
  otpLogin: {
    component: OtpLogin,
    path: '/identity/otp-login',
  },
  register: {
    component: Register,
    path: '/identity/register',
  },
  emailConfirm: {
    component: EmailConfirm,
    path: '/identity/email/confirm',
  },
  emailVerified: {
    component: EmailVerified,
    path: '/identity/email/verified',
  }
};*/

import { OtpLogin } from 'ui/identity';

export const IdentityRoutes = {
  otpLogin: {
    component: OtpLogin,
    path: '/identity/otp-login',
  }
};