export const Theme = {
  Button: {
    Secondary: {
      Background: 'var(--button-secondary-background-color)',
    },
    Disabled: {
      Background: 'var(--button-disabled-background-color)',
    },
  },
  Card: {
    Background: 'var(--card-background-color)',
    Border: 'var(--card-border)',
    BorderRadius: 'var(--card-border-radius)',
    BoxShadow: 'var(--card-box-shadow)',
  },
  Content: {
    Background: 'var(--main-content-background-color)',
  },
  Divider: {
    Color: 'var(--divider-color)',
  },
  EventCard: {
    Border: 'var(--event-card-border)',
    BoxShadow: 'var(--event-card-box-shadow)',
    Banner: {
      SoldOutColor: 'var(--banner-sold-out-background-color)',
    }
  },
  EventDetails: {
    ActionContainerBackground: 'var(--event-details-action-container-background-color)',
    ActionContainerBorder: 'var(--event-details-action-container-border)',
    ActionContainerBoxShadow: 'var(--event-details-action-container-box-shadow)',
    ContentBackground: 'var(--event-details-content-background-color)',
    ContentBoxShadow: 'var(--event-details-content-box-shadow)',
    HeaderBackground: 'var(--event-details-header-background-color)',
  },
  EventIdentity:{
    DividerColor: 'var(--event-identity-divider-color)',
    ButtonBorder: 'var(--event-identity-change-email-button-border)',
    ButtonTextColor: 'var(--event-identity-change-email-button-text-color)',
  },
  Footer: {
    BackgroundColor: 'var(--footer-background-color)',
    DividerColor: 'var(--footer-divider-color)',
    TextColor: 'var(--footer-text-color)',
    CalloutBanner: {
      Color: 'var(--footer-callout-banner-text-color)',
      Background: 'var(--footer-callout-banner-background-color)',
      BoxShadow: 'var(--footer-callout-banner-box-shadow)',
      Button: {
        Background: 'var(--footer-callout-banner-button-background-color)',
        TextColor: 'var(--footer-callout-banner-button-text-color)',
      },
    },
  },
  Header: {
    Background: 'var(--header-background-color)',
    Border: 'var(--header-border)',
    BoxShadow: 'var(--header-box-shadow)',
  },
  HeroSection: {
    Background: 'var(--hero-section-background-color)',
    BoxShadow: 'var(--hero-section-box-shadow)',
    MaxWidth: 'var(--hero-section-max-width)',
    Padding: 'var(--hero-section-padding)',
  },
  Home: {
    Background: 'var(--home-background-color)',
  },
  Loader: {
    BoxShadowColor: 'var(--loader-box-shadow-color)',
  },
  Markdown: {
    TextColor: 'var(--markdown-text-color)',
  },
  OtpInput: {
    BackgroundColor: 'var(--otp-input-background-color)',
    Border: 'var(--otp-input-border)',
    TextColor: 'var(--otp-input-color)',
  },
  SponsorBanner: {
    BackgroundColor: 'var(--sponsor-banner-background-color)',
  },
  Stepper: {
    LineColor: 'var(--stepper-line-color)',
  },
  Svg: {
    ColorDefault: 'var(--svg-color-default)',
    WeTicketLogo: 'var(--svg-weticket-logo-color)',
  },
  Text: {
    Color: 'var(--text-color)',
  },
  TextInput: {
    Background: 'var(--text-input-background-color)',
    Border: 'var(--text-input-border)',
    BorderError: 'var(--text-input-border-error)',
    BorderRadius: 'var(--text-input-border-radius)',
    Color: 'var(--text-input-color)',
  },
};
