import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TicketCard from './components/TicketCard';
import { OrderProvider } from 'providers';
import {
  isValidProviderResult,
  DataStore,
  Logger,
  Environment,
  Colors,
  Theme,
  FontFamilies,
  FontSizes,
  MediaQueries
} from 'common';
import {
  Text,
  Button,
} from 'common/components';
import { withCommon } from 'common/hocs';

const Container = styled.div`
  padding: 20px;
  max-width: 1100px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 1.6em;
  margin: 20px 0 10px 0;
`;

const TicketList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divide = styled.div`
  border-bottom: 1px solid ${Theme.Divider.Color};
  margin: 15px 0 15px 0;
  width: 100%;
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and ${MediaQueries.Md} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 15px 0 0 0;

  @media only screen and ${MediaQueries.Md} {
    width: 125px;
    margin: 0 0 0 25px;
  }
`;

const ViewMyTicketsComponent = (props) => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    if (!DataStore.get('LOGGED_IN_EMAIL')) {
      props.common.navigate('/identity/otp-login');
      return;
    }

    const fetchTickets = async () => {
      try {
        const response = await OrderProvider.getOrdersByLoggedInUser(); // Fetch tickets from API

        if (!isValidProviderResult(response)) {
          throw Error(response.message || 'Couldn\'t load order data.');
        }

        setTickets(response.data); // Assuming response.data contains the ticket orders
      } catch (err) {
        Logger.error("ViewMyTickets", "load", err);
        props.common.showToast("Oops, something went wrong, please try again.", "error");
      } finally {
        setLoading(false);
        props.common.hideLoading();
      }
    };

    fetchTickets();
  }, []); // eslint-disable-line 

  const onDownloadClick = async (token, id) => {

    props.common.showLoading();

    try {
      const response = await fetch(`${Environment.apiHost}payment/download-order`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: token,
            addReceipt: false
          }),
      });

      if (!response.ok) {
        throw new Error('Failed to download PDF');
      }

      // Create a blob from the response
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `WT-${id}.pdf`); // Name of the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      props.common.showToast('Oh no, something went wrong, please try again.');
      Logger.error('ViewMyTickets', `onDownloadClick (${id})`, error)
    }
    finally {
      props.common.hideLoading()
    }
  };

  return (
    <Container>
      <Text
        maxWidth={'1100px'}
        margin={'0 auto'}
        width={'100%'}
        display={'block'} 
        padding={'5px 0 0 0'} 
        fontSize={FontSizes.Small}
        color={Theme.Text.Color}
        onClick={() => props.common.navigate('/')}>
          ← Back
      </Text>
      <Title>My Tickets</Title>
      <Divide/>
      <EmailContainer>
        <div>
          <Text
            display={'block'}
            fontSize={FontSizes.Smaller}
            padding={'0 0 5px 0'}>
              {'Tickets displayed below were purchased using the email address below:'}
          </Text>
          <Text fontSize={FontSizes.Small} fontFamily={FontFamilies.Bold}>{DataStore.get('LOGGED_IN_EMAIL')}</Text>
        </div>
        <ButtonContainer>
          <Button
            backgroundColor={Colors.Transparent}
            border={Theme.EventIdentity.ButtonBorder}
            textColor={Theme.EventIdentity.ButtonTextColor}
            fontFamily={FontFamilies.Regular}
            fontSize={FontSizes.Smaller}
            margin={'0'}
            text={"Change Email"}
            width={'100%'}
            onClick={() => props.common.navigate('/identity/otp-login')}
          />
        </ButtonContainer>
      </EmailContainer>
      <Divide/>
      {loading && <Text>Loading tickets...</Text>}
      <TicketList>
        {tickets?.length === 0 ? (
          <Text fontSize={FontSizes.Small}>You don't have any tickets yet. Browse our events to find something exciting!</Text>
        ) : (
          tickets.map(ticket => (
            <TicketCard key={ticket.id} ticket={ticket} onDownload={() => onDownloadClick(ticket.download_token, ticket.id)} />
          ))
        )}
      </TicketList>
    </Container>
  );
};

export const ViewMyTickets = withCommon(ViewMyTicketsComponent);