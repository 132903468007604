import { wrappedAxios as axios } from './axios';

import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

export class EventProvider {

  static async getEventSiteConfig() {

    try {

      let url = `${Environment.uiHost}data/site-config.json?v=${new Date().getTime()}`;

      let res = await axios.get(url);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getPublicLiveEvents() {

    try {

      let url = `${Environment.apiHost}events`;

      let res = await axios.get(url);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getPastEvents() {

    try {

      let url = `${Environment.apiHost}events?isPast=true`;

      let res = await axios.get(url);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getEventById(id) {

    try {

      let url = `${Environment.apiHost}events/${id}`;

      let res = await axios.get(url);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProductsByEventId(event_id) {

    try {

      let url = `${Environment.apiHost}products/by-event?event_id=${event_id}`;

      let res = await axios.get(url);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}

