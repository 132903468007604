import React, { createContext, useContext } from 'react';

const WidgetContext = createContext(false);

export const WidgetProvider = ({ widget, children }) => {
  return (
    <WidgetContext.Provider value={!!widget}>
      {children}
    </WidgetContext.Provider>
  );
};

// Custom hook to use the widget context
export const useWidget = () => {
  return useContext(WidgetContext);
};