import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';

import {
  FontFamilies,
  FontSizes,
  Theme,
} from 'common';

export const Markdown = ({ markdown, defaultFontSize, padding, gap }) => {
  const renderedContent = useMemo(() => {
    const lines = (markdown ?? '').split('\n');
    let html = '';
    let inList = false;
    let listType = null; // 'ul' or 'ol'

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      const nextLine = i < lines.length - 1 ? lines[i + 1].trim() : '';
      
      // Check if this is a list item (unordered or ordered)
      const isUnorderedList = line.match(/^[*-]\s+/);
      const isOrderedList = line.match(/^\d+\.\s+/);
      
      if (isUnorderedList || isOrderedList) {
        const newListType = isUnorderedList ? 'ul' : 'ol';
        
        if (!inList || listType !== newListType) {
          // Close previous list if exists
          if (inList) {
            html += `</${listType}>`;
          }
          // Start a new list
          html += `<${newListType} class="markdown-${newListType}" style="font-size: ${defaultFontSize ?? FontSizes.Small}">`;
          inList = true;
          listType = newListType;
        }
        
        // Add list item (remove the marker)
        const content = line.replace(/^(?:[*-]|\d+\.)\s+/, '');
        html += `<li>${content}</li>`;
      } else if (inList && line === '' && !nextLine.match(/^(?:[*-]|\d+\.)\s+/)) {
        // Close the list if we have an empty line and next line is not a list item
        html += `</${listType}>`;
        inList = false;
        listType = null;
      } else if (!inList || line !== '') {
        // Process other markdown elements
        const processed = line
          // Headers
          .replace(/^# (.*$)/gim, `<h1 style="font-family: ${FontFamilies.Regular} !important">$1</h1>`)
          .replace(/^## (.*$)/gim, `<h2 style="font-family: ${FontFamilies.Regular} !important">$1</h2>`)
          .replace(/^### (.*$)/gim, `<h3 style="font-family: ${FontFamilies.Regular} !important; font-size: 1.17em !important;">$1</h3>`)
          // Bold
          .replace(/\*\*(.*?)\*\*/g, `<span style="font-family: ${FontFamilies.Bold} !important">$1</span>`)
          // Italic
          .replace(/_(.*?)_/g, '<em>$1</em>')
          // Links
          .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a class="markdown-link" href="$2" target="_blank" rel="noopener noreferrer">$1</a>');

        // Add as paragraph if it's not empty and not already wrapped in a header
        if (processed.trim() && !processed.match(/^<h[1-3]/)) {
          html += `<p style="line-height: 1.5; font-size: ${defaultFontSize ?? FontSizes.Small}">${processed}</p>`;
        } else {
          html += processed;
        }
      }
    }

    // Close any open list at the end
    if (inList) {
      html += `</${listType}>`;
    }

    return DOMPurify.sanitize(html, {
      ALLOWED_TAGS: ['h1', 'h2', 'h3', 'p', 'strong', 'em', 'code', 'pre', 'a', 'span', 'ul', 'ol', 'li'],
      ALLOWED_ATTR: ['href', 'class', 'style', 'target']
    });
  }, [markdown, defaultFontSize]);

  return (
    <div 
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: gap ?? '20px',
        color: Theme.Markdown.TextColor,
        padding: padding ?? '0',
        fontSize: defaultFontSize ?? FontSizes.Small,
        lineHeight: 1.5,
      }}
      dangerouslySetInnerHTML={{ __html: renderedContent }}
    />
  );
};
