import {
  useEffect
} from "react";

import {
  Images
} from "common";

import styled, {
  keyframes
} from "styled-components";

import ReactDOM from "react-dom";
import { useWidget } from "common/contexts";

// Create different animations for top and bottom
const fadeInFromTop = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const fadeInFromBottom = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Container with dynamic positioning based on isWidget
const Container = styled.div`
  position: fixed;
  ${props => props.isWidget ? 'bottom: 30px;' : 'top: 30px;'}
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 15px;
  pointer-events: none;
`;

const ToastWrapper = styled.div`
  padding: 20px 20px 20px 25px;
  border-radius: 5px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${props => props.isWidget ? fadeInFromBottom : fadeInFromTop} 0.5s;
  margin-bottom: 10px;
  min-width: 300px;
  max-width: 800px;
  pointer-events: auto;
  background-color: ${props => {
    switch (props.type) {
      case "info":
        return "#0051ff";
      case "success":
        return "green";
      case "error":
        return "rgb(193, 1, 1)";
      default:
        return "rgb(193, 1, 1)"; // Default to error color
    }
  }};
`;

const CloseIcon = styled.img`
  margin: 0 0 0 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const Toast = ({
  show,
  message,
  type = "error",
  duration = 4000,
  onClose,
  offset = 50
}) => {
  const isWidget = useWidget();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof onClose === "function") {
        onClose();
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [show, duration, onClose]);

  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <Container 
      style={{ [isWidget ? 'bottom' : 'top']: `${offset}px` }}
      isWidget={isWidget}
    >
      <ToastWrapper type={type} isWidget={isWidget}>
        {message}
        <CloseIcon
          alt="close"
          onClick={onClose}
          src={Images.Icons.Close}
        />
      </ToastWrapper>
    </Container>,
    document.body
  );
};
