import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Theme,
} from 'common';

const Container = styled.div`
  position: ${props => props.position || 'relative'};
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  border: ${props => props.border || Theme.Card.Border};
  border-radius: ${Theme.Card.BorderRadius};
  align-items: ${props => props.alignItems || `center`};
  background-color: ${props => props.backgroundColor || Theme.Card.Background};
  padding: ${props => props.padding || '0'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  overflow: hidden;

  ${props => props.showCursor && 'cursor: pointer;'}
  ${props => props.margin && `margin: ${props.margin};`}
  box-shadow: ${props => props.boxShadow || Theme.Card.BoxShadow};

  ${props => props.growOnHover && `
    transition: all .2s linear;
    :hover { transform: scale(1.1); }
  `}
`;

export const Card = props => {

  const onClick = () => {

    typeof props.onClick === 'function' && props.onClick(props.onClickArgs);
  };

  return (

    <Container
      border={props.border}
      position={props.position}
      alignItems={props.alignItems}
      backgroundColor={props.backgroundColor}
      flexDirection={props.flexDirection}
      padding={props.padding}
      margin={props.margin}
      height={props.height}
      width={props.width}
      growOnHover={props.growOnHover}
      boxShadow={props.boxShadow}
      showCursor={typeof props.onClick === 'function'}
      onClick={onClick}>

        {props.children}
    </Container>
  );
};
