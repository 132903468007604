import
  React
from 'react';

import {
  CSSTransition
} from 'react-transition-group';

import {
  Colors,
  Image,
  Images
} from 'common';

import styled from 'styled-components';

import {
  useWidget,
} from 'common/contexts';

import './modal.css';

// Styled-components for modal backdrop and content
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: ${props => props.isWidget ? 'flex-end' : 'center'};
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${props => props.show ? 'all' : 'none'};
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: ${Colors.GreyBrownSecond};
  padding: 20px;
  border-radius: ${props => props.isWidget ? '8px 8px 0 0' : '8px'};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  transform: ${props => {
    if (props.state === 'entering' || props.state === 'entered') {
      return props.isWidget ? 'translateY(0)' : 'scale(1)';
    } else {
      return props.isWidget ? 'translateY(100%)' : 'scale(0.9)';
    }
  }};
  opacity: ${props => (props.state === 'entering' || props.state === 'entered' ? 1 : 0)};
  width: auto;
  max-width: ${props => props.isWidget ? '100%' : '90%'};
  max-height: ${props => props.isWidget ? '80vh' : 'none'};
  overflow-y: auto;
`;

const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Modal = ({ show, onClose, children }) => {

  const isWidget = useWidget();

  return (
    <Backdrop show={show} onClick={onClose} isWidget={isWidget}>
      <CSSTransition 
        in={show} 
        timeout={300} 
        classNames={isWidget ? 'modal-bottom' : 'modal'} 
        unmountOnExit
      >
        {(state) => (
          <ModalContent 
            state={state} 
            onClick={(e) => e.stopPropagation()} 
            isWidget={isWidget}
          >
            <CloseContainer>
              <Image alt={'close'} src={Images.Icons.Close} width={'30px'} height={'auto'} onClick={onClose} />
            </CloseContainer>
            {children}
          </ModalContent>
        )}
      </CSSTransition>
    </Backdrop>
  );
};
