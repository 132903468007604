import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  FontSizes,
  FontFamilies,
  Logger,
  Theme,
  isValidEmail,
  isValidProviderResult
} from 'common';

import {
  Button,
  Checkbox,
  OtpInput,
  Stepper,
  Text,
  TextInput,
  Select,
} from 'common/components';

import {
  withCommon
} from 'common/hocs';

import {
  HtmlModal
} from 'ui/components';

import {
  EventName,
  MaxWidthContainer,
  StepperContainer,
} from 'ui/event/styled';

import {
  AuthProvider
} from 'providers';

const Divide = styled.div`
  background-color: ${Theme.EventIdentity.DividerColor};
  height: 1px;
  width: 100%;
`;

const TermsContainer = styled.div`
  justify-content: center;
  padding: 20px;
  max-width: 325px;
  margin: 0 auto;
  text-align: center;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0 0;
`;

const ButtonSpace = styled.div`
  width: 20px;
`;

const FullWidthContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CustomFieldContainer = styled.div`
  margin: 15px 0;
  width: 100%;
`;

const CustomFieldLabel = styled(Text)`
  display: block;
  padding: 0 0 5px 0;
  
  ${props => props.required && `
    &:after {
      content: ' *';
      color: ${Colors.Error};
    }
  `}
`;

const EventTicketIdentityUI = props => {

  const content = {
    email: {
      header: 'Enter Details',
      description: 'Please capture your details below',
      instruction: 'Tickets will be linked and emailed to the entered email',
      button_text: 'Submit'
    },
    otp: {
      header: 'Enter OTP',
      description: '',
      instruction: 'We sent a 4 digit code to',
      button_text: 'Submit'
    },
    logged_in: {
      header: 'Verify Email',
      description: '',
      instruction: 'Tickets will be linked and emailed to the below email. To have your tickets linked and emailed to a different email address, please use the "Change Email" option.',
      button_text: 'Continue'
    }
  };

  const [state, setState] = React.useState({
    currentView: DataStore.get('LOGGED_IN_EMAIL') && DataStore.get('ACCESS_TOKEN') ? 'logged_in' : 'email',
    email: '',
    otp: '',
    event: DataStore.get('selectedEvent') || {},
    ...(DataStore.get('LOGGED_IN_EMAIL') && DataStore.get('ACCESS_TOKEN') ? content.logged_in : content.email),
  });

  const [showTsAndCs, setShowTsAndCs] = React.useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = React.useState(false);
  const [showOrganizerTerms, setShowOrganizerTerms] = React.useState(false);
  const [showOrganizerPrivacyPolicy, setShowOrganizerPrivacyPolicy] = React.useState(false);
  const [receiveMarketing, setReceiveMarketing] = React.useState(true);
  const [showMarketingCheckbox, setShowMarketingCheckbox] = React.useState(false);
  const [otpTimeLeft, setOtpTimeLeft] = React.useState(0);
  const otpRef = React.useRef(null);

  const onEmailChange = (val) => {

    setState(prevState => ({
      ...prevState,
      email: val?.trim()
    }));
  };

  const onOTPChange = (val) => {

    setState(prevState => ({
      ...prevState,
      otp: val
    }));
  };

  const onResendOtpClick = () => {

    setOtpTimeLeft(30);

    AuthProvider.resendCode(DataStore.get('verifyGuid'))
        .then(ret => {

          if (!isValidProviderResult(ret)) {

            // TODO: log error
            return;
          }
        })
        .catch(e => {

          Logger.error('EventTicketIdentity', 'onResendOtpClick', e);
          props.common.showToast('Oh no, something went wrong, please try again.');
        });
  };

  const onSubmitClick = () => {

    if (state.currentView === 'email') {

      if (!isValidEmail(state.email)) {

        props.common.showToast('Please enter a valid email and try again.');
        return;
      }

      if (!validateCustomFields()) {
        return;
      }

      DataStore.set('selectedEvent', {
        ...state.event,
        custom_fields: Object.values(state.customFields || {})
      });

      props.common.showLoading();

      // API call to send OTP
      AuthProvider.requestCode(state.email)
        .then(ret => {

          props.common.hideLoading();

          if (!isValidProviderResult(ret)) {

            // TODO: show error
            props.common.showToast('Oh no, we weren\'t able to send your OTP, please try again.');
            return;
          }

          DataStore.set('verifyGuid', ret.data.guid);

          if (!ret.data.existingUser) {
            setShowMarketingCheckbox(true);
          }

          setState(prevState => ({
            ...prevState,
            ...content.otp,
            instruction: <div style={{ textAlign: 'center' }}>{content.otp.instruction}<br/><span style={{ fontFamily: FontFamilies.Bold}}>{state.email}</span></div>,
            currentView: 'otp'
          }));
        })
        .catch(e => {

          Logger.error('EventTicketIdentity', 'requestCode', e);

          props.common.showToast('Oh no, something went wrong, please try again.');

          props.common.hideLoading();
        });
    }

    if (state.currentView === 'otp') {

      if (state.otp.length !== 4) {

        // TODO: show error
        props.common.showToast('Please enter the 4 digit OTP sent to your email.');
        return;
      }

      props.common.showLoading();

      AuthProvider.submitCode(DataStore.get('verifyGuid'), state.otp, showMarketingCheckbox && receiveMarketing)
        .then(ret => {

          props.common.hideLoading();

          if (!isValidProviderResult(ret)) {

            // TODO: show error
            props.common.showToast('Oh no, we weren\'t able to verify your OTP, please try again.');
            return;
          }

          if (!ret.data.verified) {

            if (otpRef.current) {
              otpRef.current.clear();
            }

            props.common.showToast('Incorrect OTP.  Please check your OTP and try again.');
            return;
          }

          DataStore.set('ACCESS_TOKEN', ret.data.token);
          DataStore.set('LOGGED_IN_EMAIL', state.email);

          props.common.navigate('/event/payment');
      })
      .catch(e => {

        Logger.error('EventTicketIdentity', 'submitCode', e);

        props.common.showToast('Oh no, something went wrong, please try again.');

        props.common.hideLoading();
      });
    }

    if (state.currentView === 'logged_in') {

      if (!validateCustomFields()) {
        return;
      }

      DataStore.set('selectedEvent', {
        ...state.event,
        custom_fields: Object.values(state.customFields || {})
      });

      props.common.navigate('/event/payment');
    }
  };

  const onBackClick = () => {

    if (state.currentView === 'otp') {

      setState(prevState => ({
        ...prevState,
        ...content.email,
        currentView: 'email'
      }));

      return;
    }

    props.common.history.goBack();
  };

  const renderCustomFields = () => {
    if (!state.event?.custom_fields?.length) return null;

    return state.event.custom_fields.map((field, index) => (
      <CustomFieldContainer key={`custom-field-${index}`}>
        <CustomFieldLabel
          fontSize={FontSizes.Smaller}
          required={field.required}>
          {field.label}
        </CustomFieldLabel>
        {renderCustomField(field)}
      </CustomFieldContainer>
    ));
  };

  const validateCustomField = (field, value) => {
    if (field.required && (!value || (Array.isArray(value) && !value.length) || (typeof value === 'string' && !value.trim()))) {
      return `${field.label} is required`;
    }

    if (!value || (typeof value === 'string' && !value.trim())) return null; // Don't validate empty optional fields

    switch (field.type) {
      case 'email':
        return isValidEmail(value) ? null : `${field.label} must be a valid email`;
      
      case 'tel':
        const phoneRegex = /^\+?[\d\s-]{10,}$/;
        return phoneRegex.test(value) ? null : `${field.label} must be a valid phone number`;
      
      case 'number':
        return !isNaN(value) ? null : `${field.label} must be a valid number`;
      
      default:
        return null;
    }
  };

  const validateCustomFields = () => {
    if (!state.event?.custom_fields?.length) return true;

    const errors = state.event.custom_fields.reduce((acc, field) => {
      const error = validateCustomField(field, state.customFields?.[field.id]?.value);
      if (error) acc.push(error);
      return acc;
    }, []);

    if (errors.length > 0) {
      props.common.showToast(errors.join('. '));
      return false;
    }

    return true;
  };

  const onCustomFieldChange = (field, value) => {

    if (!field) return;
    if (!field.id) field.id = new Date().getTime();

    setState(prevState => ({
      ...prevState,
      customFields: {
        ...prevState.customFields,
        [field.id]: {
          ...field,
          value: value
        }
      }
    }));
  };

  const renderCustomField = (field) => {
    const value = state.customFields?.[field.id]?.value || '';
    const commonProps = {
      width: '100%',
      value: value,
      onChange: (value) => onCustomFieldChange(field, value),
      placeholder: field.placeholder || `Enter ${field.label.toLowerCase()}...`,
    };
  
    switch (field.type) {
      case 'textarea':
        return (
          <TextInput
            {...commonProps}
            multiline={true}
          />
        );
  
      case 'number':
        return (
          <TextInput
            {...commonProps}
            type="number"
          />
        );
  
      case 'email':
        return (
          <TextInput
            {...commonProps}
            type="email"
          />
        );
  
      case 'tel':
        return (
          <TextInput
            {...commonProps}
            type="tel"
          />
        );
  
      case 'checkbox':
        return (
          <Checkbox
            checked={!!value}
            label={field.placeholder || ''}
            onClick={(checked) => onCustomFieldChange(field.id, checked)}
          />
        );
  
      case 'select':
        return (
          <Select
            {...commonProps}
            options={field.options.map(opt => ({
              value: opt,
              label: opt
            }))}
            placeholder={`Select ${field.label.toLowerCase()}...`}
          />
        );
  
      default: // text
        return (
          <TextInput
            {...commonProps}
            type="text"
          />
        );
    }
  };

  React.useEffect(() => {
    if (otpTimeLeft === 0) return; // Stop the timer when it reaches 0

    const timerId = setInterval(() => {
      setOtpTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId); // Clean up the interval on unmount
  }, [otpTimeLeft]);

  React.useEffect(() => {
    EventTicketIdentityUI.timestamp = new Date().getTime();
  }, []);

  const organiserTerms = window.location.hostname.includes('diazsummersplash')
    ? 'https://storage.googleapis.com/website777/weTicket/diazSummerSplash/organizer_terms.html?v=' + EventTicketIdentityUI.timestamp
    : window.location.hostname.includes('bolandrugby')
      ? 'https://storage.googleapis.com/website777/weTicket/boland_rugby/legal/TermsandConditions.html?v=' + EventTicketIdentityUI.timestamp
      : '';

  const organiserPrivacyPolicy = window.location.hostname.includes('bolandrugby')
    ? 'https://storage.googleapis.com/website777/weTicket/boland_rugby/legal/PrivacyPolicy.html?v=' + EventTicketIdentityUI.timestamp
    : '';

  return (
    <>
    <Text
      maxWidth={'1100px'}
      margin={'0 auto'}
      width={'100%'}
      display={'block'} 
      padding={'20px 15px 0 15px'} 
      fontSize={FontSizes.Small}
      color={Theme.Text.Color}
    >
      <span style={{cursor: 'pointer'}} onClick={() => props.common.navigate(-1)}>← Back</span>
    </Text>

    <EventName>{state.event.name}</EventName>

    <Text display={'block'} padding={'0 0 5px 0'} textAlign={'center'} fontSize={FontSizes.Small}>{state.event.location_display}</Text>

    <Text display={'block'} padding={'0 0 20px 0'} textAlign={'center'} fontSize={FontSizes.Small}>{state.event.displayDate}</Text>

    <FullWidthContainer>

      <StepperContainer>

        <Stepper
          items={[{text: 'ORDER'}, {text: 'DETAILS'}, {text: 'PAYMENT'}]}
          selectedIndex={1}
        />

      </StepperContainer>

      <MaxWidthContainer>

        <Text
          display={'block'}
          fontFamily={FontFamilies.Bold}
          fontSize={FontSizes.Big}>
            {state.header}
        </Text>

        <Text
          display={'block'}
          fontSize={FontSizes.Smaller}
          padding={'0 0 15px 0'}>
            {state.description}
        </Text>

        <Divide/>

        <Text
          display={'block'}
          fontSize={FontSizes.Smaller}
          padding={'15px 0 15px 0'}>
            {state.instruction}
        </Text>

        { state.currentView === 'email' &&
        
          <>
            <TextInput
              type={'email'}
              placeholder={'Email address...'}
              width={'100%'}
              value={state.email}
              onChange={onEmailChange}
            />
            {renderCustomFields()}
          </>
        }

        { state.currentView === 'otp' &&
          <>
          <OtpInput
            ref={otpRef} 
            onChange={onOTPChange}
          />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            { otpTimeLeft > 0 && 
              <>
              <Text fontSize={FontSizes.Smaller}>Your OTP is on the way!</Text>
              <Text fontSize={FontSizes.Smaller} textAlign={'center'}>You can resend in {otpTimeLeft} seconds.</Text>
              </>
            }
            { otpTimeLeft <= 0 && <Text textDecoration={'underline'} fontSize={FontSizes.Smaller} onClick={onResendOtpClick}>Resend OTP</Text> }
          </div>
          </>
        }

        { state.currentView === 'logged_in' &&
        
          <>
          <Text fontFamily={FontFamilies.Bold}>{DataStore.get('LOGGED_IN_EMAIL')}</Text>
          <Button
            backgroundColor={Colors.Transparent}
            border={Theme.EventIdentity.ButtonBorder}
            textColor={Theme.EventIdentity.ButtonTextColor}
            fontFamily={FontFamilies.Regular}
            fontSize={FontSizes.Smaller}
            margin={'15px 0 0 0'}
            text={"Change Email"}
            width={'125px'}
            onClick={() => setState(prevState => ({
              ...prevState,
              ...content.email,
              currentView: 'email'
            }))}
          />
          {renderCustomFields()}
          </>

        }
      </MaxWidthContainer>

      <div style={{ flex: 1, minHeight: props.common.isWidget ? '30px' : 'auto' }} />

      <MaxWidthContainer padding={'0'}>

        <ActionContainer>

          <MaxWidthContainer>

          { (state.currentView === 'email' || state.currentView === 'logged_in') &&

            <TermsContainer>

              <Text fontSize={FontSizes.Smaller}>
                {window.location.hostname.includes('bolandrugby') && <>By clicking submit you agree to our <Text onClick={() => setShowOrganizerTerms(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Organizer Terms</Text>, <Text onClick={() => setShowOrganizerPrivacyPolicy(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Organizer Privacy Policy</Text>, <Text onClick={() => setShowTsAndCs(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Terms of Use</Text> and <Text onClick={() => setShowPrivacyPolicy(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Privacy Policy</Text></>}
                {window.location.hostname.includes('diazsummersplash') && <>By clicking submit you agree to our <Text onClick={() => setShowOrganizerTerms(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Organizer Terms</Text>, <Text onClick={() => setShowTsAndCs(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Terms of Use</Text> and <Text onClick={() => setShowPrivacyPolicy(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Privacy Policy</Text></>}
                {!window.location.hostname.includes('diazsummersplash') && !window.location.hostname.includes('bolandrugby') && <>By clicking submit you agree to our <Text onClick={() => setShowTsAndCs(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Terms of Use</Text> and <Text onClick={() => setShowPrivacyPolicy(true)} fontSize={FontSizes.Smaller} fontFamily={FontFamilies.Bold}>Privacy Policy</Text></>}
              </Text>

            </TermsContainer>
          }

          { state.currentView === 'otp' && showMarketingCheckbox &&

            <Checkbox
              defaultChecked={receiveMarketing}
              padding={'15px 0 15px 2px'}
              label={'Stay in the loop! Tick this box if you would like to hear about upcoming events.'}
              onClick={(checked) => setReceiveMarketing(checked)}
            />
          }

          <Divide/>

          <ActionButtonContainer>

            <Button onClick={onBackClick} backgroundColor={Theme.Button.Secondary.Background}>Back</Button>

            <ButtonSpace/>

            <Button onClick={onSubmitClick}>{state.button_text}</Button>

          </ActionButtonContainer>

          </MaxWidthContainer>

        </ActionContainer>

      </MaxWidthContainer>

    </FullWidthContainer>

    <HtmlModal url={'https://storage.googleapis.com/website777/weTicket/diazSummerSplash/weticket_terms.html?v=' + EventTicketIdentityUI.timestamp} show={showTsAndCs} onClose={() => setShowTsAndCs(false)}/>
    <HtmlModal url={'https://storage.googleapis.com/website777/weTicket/diazSummerSplash/weticket_privacy_policy.html?v=' + EventTicketIdentityUI.timestamp} show={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)}/>
    <HtmlModal url={organiserTerms} show={showOrganizerTerms} onClose={() => setShowOrganizerTerms(false)}/>
    <HtmlModal url={organiserPrivacyPolicy} show={showOrganizerPrivacyPolicy} onClose={() => setShowOrganizerPrivacyPolicy(false)}/>

    </>
  );
};

export const EventTicketIdentity = withCommon(
  EventTicketIdentityUI, {
    showLoadingOnLoad: false
  }
);