import
  React, {
  Component,
} from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  FontFamilies,
  FontSizes,
  Image,
  MediaQueries,
  Theme,
  Logger,
  isValidProviderResult,
  updateThemeColor,
} from 'common';

import {
  Button,
  EventCard,
  OrganiserCard,
  Footer,
  H2,
  Text,
  Modal,
} from 'common/components';

import {
  WeTicketLogo
} from 'common/components/svg';

import {
  withCommon,
} from 'common/hocs';

import {
  EventProvider
} from 'providers';

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${Theme.HeroSection.Background};
  box-shadow: ${Theme.HeroSection.BoxShadow};
`;

const MainImage = styled.img`
  display: ${props => props.isMobile ? 'block' : 'none'};
  max-width: ${Theme.HeroSection.MaxWidth};
  background-color: ${Theme.HeroSection.Background};
  padding: ${Theme.HeroSection.Padding};
  width: 100%;

  @media only screen and ${MediaQueries.Sm} {
    display: ${props => props.isMobile ? 'none' : 'block'};
  }
`;

const BottomFadeContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  background-color: ${Colors.Black};
  background: linear-gradient(0deg, ${Colors.Black} 0%, ${Colors.Transparent} 100%);
  pointer-events: none;
`;

const Container = styled.div`
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 20px;
`;

const EventsContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr;
  padding: 0 0 40px 0;

  @media only screen and ${MediaQueries.Md} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3vmin;
  }

  @media only screen and ${MediaQueries.Lg} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3vmin;
  }
`;

const CalloutBanner = styled.img`
  width: 100%;
  max-width: 850px;
  margin: 25px auto 0 auto;

  @media only screen and ${MediaQueries.Md} {
    border-radius: 10px;
  }
`;

const FooterMobileSpace = styled.div`
  height: 15px;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

const SponsorImage = styled.img`
  display: none;

  @media only screen and ${MediaQueries.Md} {
    display: inline;
  }
`;

const SponsorImageMobile = styled.img`
  display: inline;

  @media only screen and ${MediaQueries.Md} {
    display: none;
  }
`;

const SliderContainer = styled.div`
  overflow: hidden;
  background: white;
  position: relative;
  width: 100%;
`;

const SlideTrack = styled.div`
  display: flex;
  height: 150px;
  padding: 10px 0;
  animation: scroll ${props => props.imageCount * 10}s linear infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-${props => props.imageWidth * props.imageCount}px);
    }
  }

  /*&:hover {
    animation-play-state: paused;
  }*/

  @media only screen and ${MediaQueries.Md} {
    height: 200px;
  }
`;

const SlideImage = styled.img`
  height: 100%;
  width: 200px;
  padding: 0 40px 0 0;
  object-fit: contain;
`;

const SellYourTicketsContainer = styled.div`
  background-color: ${Theme.Footer.CalloutBanner.Background};
  width: 100%;
  padding: 20px 0;
  box-shadow: ${Theme.Footer.CalloutBanner.BoxShadow};
  margin-top: ${props => props.addMargin ? '50px' : '20px'};
  color: ${Theme.Footer.CalloutBanner.Color};
`;

const SellYourTicketsRow = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 10px;

  @media only screen and ${MediaQueries.Md} {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const HeroTitle = styled(Text)`
  font-size: 2rem;
  padding: 0 15px 6px 0;

  @media only screen and ${MediaQueries.Md} {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled(Text)`
  font-size: 1.8rem;
  padding: 0 15px 6px 0;

  @media only screen and ${MediaQueries.Md} {
    font-size: 2rem;
  }
`;

const HeroSubSubtitle = styled(Text)`
  font-size: 1.1rem;
  padding: 10px 15px 30px 15px;

  @media only screen and ${MediaQueries.Md} {
    font-size: 1.4rem;
  }
`;

const PoweredByContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  gap: 20px;

  @media only screen and ${MediaQueries.Md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

class homeUI extends Component {

  state = {
    coverImageDesktop: '',
    coverImageMobile: '',
    sponsorBannerDesktop: {},
    sponsorBannerMobile: {},
    sponsorSliderImages: [],
    calloutBanner: {},
    footerLogos: [],
    mainEvent: {},
    events: [],
    pastEvents: [],
    organiserCards: [],
    merchCards: [],
    heroConfig: {},
    widgetConfig: {},
    showTerms: false,
    showPrivacyPolicy: false,
    showContactForm: false,
  };

  onGetTicketsClick = (event) => {

    DataStore.set('homeScrollPosition', document.getElementById('main-content').scrollTop);
    this.props.common.navigate(`/event/details/${event.id}`);
  };

  onGetMerchClick = (eId) => {

    DataStore.set('homeScrollPosition', document.getElementById('main-content').scrollTop);
    this.props.common.navigate(`/event/merch/select?e_id=${eId}`);
  };

  load = async () => {

    let siteConfigRet = await EventProvider.getEventSiteConfig();

    if (siteConfigRet.ok && siteConfigRet.data) {

      const subdomain = window.location.hostname.split('.')[0];
      const siteConfig = siteConfigRet.data[subdomain] || siteConfigRet.data.default;

      if (siteConfig) {

        const merchCards = (siteConfig.merch_cards || []).map((m, index) => (
          <EventCard
            key={`m-card-${index}`}
            image={m.cover_media_url}
            eventText={m.title}
            location={m.sub_title}
            height={'250px'}
            width={'100%'}
            hideFavButton={true}
            price={m.price_text}
            pricePrefix={m.price_pre_text}
            showDate={false}
            onClick={() => this.onGetMerchClick(m.event_id)}
          />
        ));

        const footerLogoComponents = (siteConfig.footer_logos || []).map((logo, index) => (
          <Image
            key={`footerlogo-${index}`}
            src={logo.src}
            height={logo.height}
            width={logo.width}
            margin={'20px 0 20px 30px'}
            onClick={() => window.open(logo.redirect_url, '_blank')}
          />
        ));

        const sponsorSliderImages = (siteConfig.sponsor_banner?.slider_images || []).map((image, index) => (
          <SlideImage key={`sponsor-slider-image-${index}`} src={image} alt="Sponsor Slider Image" />
        ));

        if (siteConfig.theme_color && !this.props.common.isWidget) {
          updateThemeColor(siteConfig.theme_color);
        }

        const organiserCards = (siteConfig.organisers || []).map((organiser, index) => (
          <OrganiserCard
            key={`organiser-card-${index}`}
            image={organiser.cover_image}
            name={organiser.name}
            height={'250px'}
            width={'100%'}
            onClick={() => {
              window.location.href = organiser.link;
            }}
          />
        ));

        this.setState({
          coverImageDesktop: siteConfig.splash_desktop,
          coverImageMobile: siteConfig.splash_mobile || siteConfig.splash_desktop,
          sponsorBannerDesktop: siteConfig.sponsor_banner_desktop,
          sponsorBannerMobile: siteConfig.sponsor_banner_mobile || siteConfig.sponsor_banner_desktop,
          calloutBanner: siteConfig.callout_banner || {},
          footerLogos: footerLogoComponents,
          sponsorSliderImages: sponsorSliderImages,
          merchCards: merchCards,
          organiserCards: organiserCards,
          heroConfig: {
            title: siteConfig.hero_title,
            subtitle: siteConfig.hero_subtitle,
            sub_subtitle: siteConfig.hero_sub_subtitle,
            button_text: siteConfig.hero_button_text
          },
          widgetConfig: siteConfig.widget_config || {}
        });
      }

      if (siteConfig?.show_past_events) {

        let pEvents = await EventProvider.getPastEvents();

        if (isValidProviderResult(pEvents)) {
          this.setState({
            pastEvents: (pEvents.data || []).map(event => {
            
              const soldOut = event.total_available_tickets <= 0;

              return (
                <EventCard
                  key={`past-event-${event.id}`}
                  image={event.cover_media_url}
                  eventText={event.name}
                  location={event.location_display}
                  height={'250px'}
                  width={'100%'}
                  hideFavButton={true}
                  month={event.displayMonth}
                  day={event.displayDay}
                  price={event.priceDisplay}
                  pricePrefix={event.pricePrefix}
                  time={event.displayTime}
                  callout={soldOut
                    ? 'Sold Out'
                    : event.total_available_tickets < 10
                      ? `Only ${event.total_available_tickets} left`
                      : ''
                    }
                    showDate={!event.is_multi_event}
                    onClick={() => !soldOut && this.onGetTicketsClick(event)}
                />
              );
            })
          });
        }
      }
    }

    let ret = await EventProvider.getPublicLiveEvents();

    if (!isValidProviderResult(ret)) {

      // TODO: log error
      // Show toast
      return;
    }

    if (!Array.isArray(ret.data) || !ret.data.length) {

      // TODO: handle no events found to display
      return;
    }

    let eventComponents = [];

    for (const event of ret.data) {

      const isSoldOut = event.total_available_tickets <= 0;

      eventComponents.push(
        <EventCard
          key={event.id}
          image={event.cover_media_url}
          eventText={event.name}
          location={event.location_display}
          height={'250px'}
          width={'100%'}
          hideFavButton={true}
          month={event.displayMonth}
          day={event.displayDay}
          price={event.priceDisplay}
          pricePrefix={event.pricePrefix}
          time={event.displayTime}
          callout={isSoldOut
            ? 'Sold Out'
            : event.total_available_tickets < 10
              ? `Only ${event.total_available_tickets} left`
              : ''
          }
          showDate={!event.is_multi_event}
          onClick={() => !isSoldOut && this.onGetTicketsClick(event)}
        />
      );
    }

    this.setState({
      events: eventComponents
    });

    setTimeout(() => {
      const scrollPosition = DataStore.get('homeScrollPosition');
      if (scrollPosition) {
        document.getElementById('main-content').scrollTo(0, parseInt(scrollPosition, 10));
        DataStore.clear('homeScrollPosition')
      }
    }, 0);
  };

  componentDidMount = () => {

    homeUI.timestamp = new Date().getTime();

    DataStore.clear('verifyGuid');
    DataStore.clear('selectedTickets');
    DataStore.clear('selectedEvent');

    this.load()
      .catch(e => {
        
        Logger.error('Home', 'load', e);

        this.props.common.showToast('Oh no, we weren\'t able to load event details, please try again.');

        this.props.common.hideLoading();
      })
      .finally(() => this.props.common.hideLoading());
  };

  render () {

    return (

      <>

        { (!this.props.common.isWidget || this.state.widgetConfig.show_splash_image) &&
          <MainContainer>

            <MainImage
              isMobile={false}
              src={this.state.coverImageDesktop || this.state.mainEvent.featured_media_url || this.state.mainEvent.cover_media_url}
            />

            <MainImage
              isMobile={true}
              src={this.state.coverImageMobile || this.state.mainEvent.featured_media_url || this.state.mainEvent.cover_media_url}
            />

            { this.state.heroConfig?.title &&
              <HeroContainer>
                <HeroTitle color={Colors.White} fontFamily={FontFamilies.Bold} fontSize={'3rem'}>{this.state.heroConfig.title}</HeroTitle>
                <HeroSubtitle color={Colors.White} fontFamily={FontFamilies.Bold} fontSize={FontSizes.Biggest}>{this.state.heroConfig.subtitle}</HeroSubtitle>
                <HeroSubSubtitle color={Colors.White} fontSize={FontSizes.Big} padding={'10px 0 20px 0'}>{this.state.heroConfig.sub_subtitle}</HeroSubSubtitle>
                <Button onClick={() => window.open('https://features.weticket.co.za', '_blank')} backgroundColor={Colors.White} textColor={Colors.Black} fontFamily={FontFamilies.Bold} border={'none'} width={'185px'} text={this.state.heroConfig.button_text}/>
              </HeroContainer>
            }

            {false && <BottomFadeContainer/>}

          </MainContainer>
        }

        { !this.props.common.isWidget && this.state.calloutBanner?.src &&
        
          <CalloutBanner
            alt={'Callout Banner'}
            src={this.state.calloutBanner.src}
          />
        }

        <Container>

          {!this.props.common.isWidget && <H2 margin={'45px 0 30px 0'}>Upcoming Events</H2>}

          {this.props.common.isWidget &&

            <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '15px 0', borderBottom: `1px solid ${Theme.Divider.Color}` }}>
              <H2 margin={'0'} style={{ fontSize: '20px' }}>Upcoming Events</H2>
              <Button 
                onClick={() => {
                  if (DataStore.get('LOGGED_IN_EMAIL') && DataStore.get('ACCESS_TOKEN')) {
                    this.props.common.navigate('/profile/view-my-tickets');
                  } else {
                    this.props.common.navigate('/identity/otp-login');
                  }
                }} 
                backgroundColor={Colors.Primary} 
                textColor={Colors.White} 
                fontFamily={FontFamilies.Bold} 
                border={'none'} 
                width={'125px'} 
                text={'MY TICKETS'}
              />
            </div>
            <div style={{ height: '35px' }} />
            </>
          }
          
            { this.state.events?.length > 0
            
              ? <EventsContainer>

                  {this.state.events}

                </EventsContainer>

              : <Text padding={'0 0 140px 0'}>There are no upcoming events scheduled at the moment.</Text>
            }

        </Container>

        { this.state.organiserCards?.length > 0 &&

          <Container>

            <H2 margin={'0 0 0.8em 0'}>Organisers</H2>
            
            <EventsContainer>

              {this.state.organiserCards}

            </EventsContainer>

          </Container>
        }

        { this.state.pastEvents?.length > 0 &&

          <Container>

            <H2 margin={'0 0 0.8em 0'}>Past Events</H2>
            
              <EventsContainer>

                {this.state.pastEvents}

              </EventsContainer>

          </Container>
        }

        { this.state.merchCards.length > 0 &&
        
          <Container>

            <H2 margin={'0 0 0.8em 0'}>Merchandise</H2>
            
              <EventsContainer>

                {this.state.merchCards}

              </EventsContainer>

          </Container>
        }

        <div style={{ backgroundColor: Theme.SponsorBanner.BackgroundColor, width: '100%' }}>

          <Container>
            { this.state.sponsorBannerDesktop?.src &&
              <SponsorImage
                alt={'sponsors'}
                style={{
                  width: this.state.sponsorBannerDesktop.width || '100%',
                  height: this.state.sponsorBannerDesktop.height || 'auto'
                }}
                src={this.state.sponsorBannerDesktop.src + '?t=' + + homeUI.timestamp} />
            }
            { this.state.sponsorBannerMobile?.src &&
              <>
                <FooterMobileSpace/>
                <SponsorImageMobile
                  alt={'sponsort'}
                  style={{
                    width: this.state.sponsorBannerMobile.width || '100%',
                    height: this.state.sponsorBannerMobile.height || 'auto'
                  }}
                  src={this.state.sponsorBannerMobile.src + '?t=' + + homeUI.timestamp}
                />
              </>
            }

            { this.state.sponsorSliderImages.length > 0 &&
              <SliderContainer>
                <SlideTrack imageCount={this.state.sponsorSliderImages.length} imageWidth={200}>
                  {this.state.sponsorSliderImages}
                  {this.state.sponsorSliderImages}
                </SlideTrack>
              </SliderContainer>
            }

          </Container>

        </div>

        { this.props.common.isWidget &&
          <Container>
            <div style={{ borderBottom: `1px solid ${Theme.Divider.Color}` }} />
            <PoweredByContainer>
              <div style={{ display: 'flex', gap: '7px', alignItems: 'center' }}>
                <Text fontSize={FontSizes.Small}>Powered by</Text>
                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => window.open('https://weticket.co.za', '_blank')}>
                  <WeTicketLogo
                    height={38} 
                    width={175}
                  />
                </div>
              </div>
              <Button
                backgroundColor={Colors.Transparent}
                border={`2px solid ${Theme.Text.Color}`}
                textColor={Theme.EventIdentity.ButtonTextColor}
                fontSize={FontSizes.Smaller}
                fontFamily={FontFamilies.Bold}
                width={'185px'}
                text={'SELL ON WETICKET'}
                onClick={() => window.open('https://features.weticket.co.za', '_blank')}
              />
            </PoweredByContainer>
          </Container>
        }

        { !this.props.common.isWidget &&

          <SellYourTicketsContainer
            addMargin={this.state.sponsorBannerDesktop?.src || this.state.sponsorSliderImages.length > 0}>

            <SellYourTicketsRow>

              <Text fontSize={FontSizes.Regular} color={Theme.Footer.CalloutBanner.Color}>
                Hosting an event? Sell your tickets on WeTicket.
              </Text>

              <Button
                backgroundColor={Theme.Footer.CalloutBanner.Button.Background}
                textColor={Theme.Footer.CalloutBanner.Button.TextColor}
                fontFamily={FontFamilies.Bold}
                border={'none'}
                width={'185px'}
                text={'SELL YOUR TICKETS'}
                onClick={() => this.setState({showContactForm: true})}
              />  

            </SellYourTicketsRow>

          </SellYourTicketsContainer>
        }

        { !this.props.common.isWidget &&
          <div style={{ backgroundColor: Theme.Footer.BackgroundColor, width: '100%' }}>

            <Container>

              <Footer/>

            </Container>
          </div>
        }

        <Modal show={this.state.showContactForm} onClose={() => this.setState({showContactForm: false})}>
          <div style={{width: '800px', height: '70vh', maxWidth: '85vw'}}>
            <iframe 
              title={'Contact Us'} 
              src={'https://features.weticket.co.za/contactUs/contact.html?v=' + window.policyTimestamp} 
              width={'100%'} 
              height={'100%'}
              style={{backgroundColor: 'white', border: 'none'}}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export const Home = withCommon(homeUI, {
  applyMaxWidth: false,
  transitionHeader: true,
  contentBackgroundColor: Theme.Home.Background,
  showHeaderYourTickets: true,
});