import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  MediaQueries,
  Theme,
} from 'common';

import {
  H4,
} from 'common/components/text';

import {
  Card,
} from './Card';

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0 0 10px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: ${props => props.width || '220px'};
  padding-bottom: 56.25%;

  ${props => props.onClick && 'cursor: pointer;'}
`;

const Image = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const DetailsContainer = styled.div`
  width: 100%;
  padding: 3%;
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and ${MediaQueries.Md} {
    padding: 5%;
  }
`;

const Callout = styled.div`
  border-radius: 5px;
  background-color: ${Theme.EventCard.Banner.SoldOutColor};
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  color: ${Colors.White};
`;

export const OrganiserCard = props => {

  const onCardClick = () => {
    typeof props.onClick === 'function' && props.onClick();
  };

  return (

    <Card
      position={'relative'}
      flexDirection={'column'}
      border={Theme.EventCard.Border}
      boxShadow={Theme.EventCard.BoxShadow}
      width={'100%'}
      height={'100%'}
      onClick={onCardClick}>
    
      <ImageContainer
        width={props.width}
        height={props.height}>

        <Image
          src={props.image}
        />

        { props.callout &&

          <Callout>{props.callout}</Callout>
        }

      </ImageContainer>

      <DetailsContainer>

        <TextContainer>

          <H4
            margin={'0'}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            lineHeight={1}>

              {props.name}
          </H4>

        </TextContainer>

      </DetailsContainer>
    </Card>
  );
};