import {
  Environment,
} from './environment';

export const Colors = {
  PrimaryOne: 'var(--primary-color)',
  DarkBlue: '#061014',
  Black: '#151719',
  //Black: '#000000',
  White: '#ffffff',
  Transparent: '#00000000',
  Grey: '#707070',
  DarkGrey: '#4B494D',
  LightGreyBrown: '#4C494D',
  LightGreyBrownSecond: '#333235',
  GreyBrownSecond: '#292A2D',
  GreyBrown: '#202124',
};

export const FontFamilies = {
  Regular: 'MontserratRegular',
  Bold: 'MontserratBold',
  ExtraBold: 'MontserratExtraBold',
};

export const FontSizes = {
  Smallest: '0.7rem',
  Smaller: '0.8rem',
  Small: '0.9rem',
  Regular: '1rem',
  Big: '1.3rem',
  Bigger: '1.6rem',
  Biggest: '1.9rem',
  Huge: '2.2rem',
};

export const Images = {
  Backgrounds: {
    Login: `${Environment.assetBase}/images/backgrounds/login-background.jpg`,
  },
  Icons: {
    Arrow: `${Environment.assetBase}/images/icons/arrow.svg`,
    ArrowLeft: `${Environment.assetBase}/images/icons/arrow-left.svg`,
    CircleTick: `${Environment.assetBase}/images/icons/circle-tick.svg`,
    Calender: `${Environment.assetBase}/images/icons/calendar.svg`,
    Close: `${Environment.assetBase}/images/icons/close.svg`,
    Dislike: `${Environment.assetBase}/images/icons/dislike.svg`,
    EmailSent: `${Environment.assetBase}/images/icons/email-sent.svg`,
    Heart: `${Environment.assetBase}/images/icons/heart-icon.svg`,
    LeftArrowCircle: `${Environment.assetBase}/images/icons/left-arrow-circle.svg`,
    Like: `${Environment.assetBase}/images/icons/like.svg`,
    Location: `${Environment.assetBase}/images/icons/location.svg`,
    Pending: `${Environment.assetBase}/images/icons/pending.svg`,
    Pin: `${Environment.assetBase}/images/icons/pin.svg`,
    Share: `${Environment.assetBase}/images/icons/share.svg`,
    Tick: `${Environment.assetBase}/images/icons/tick.svg`,
    Thunderbolt: `${Environment.assetBase}/images/icons/thunderbolt.svg`,
    UserIcon: `${Environment.assetBase}/images/icons/user-icon.svg`,
    Watchlist: `${Environment.assetBase}/images/icons/watchlist.svg`,
    Wallet: `${Environment.assetBase}/images/icons/wallet.svg`,
  },
  Airline: `${Environment.assetBase}/images/airline.png`,
  Bokke: `${Environment.assetBase}/images/bokke.png`,
  Event: `${Environment.assetBase}/images/event.png`,
  Logo: `${Environment.assetBase}/images/logo.svg`,
  SummerSplashSponsors: `https://storage.googleapis.com/website777/weTicket/diazSummerSplash/Sponsor.png`,
  IKLogo: `https://storage.googleapis.com/website777/weTicket/diazSummerSplash/ik.png`,
};

export const Messages = {
  TechnicalError: 'Unfortunately a technical error occurred, please try again.',
  FileUploadError: 'We were unable to upload your file, please try again.',
  FormError: 'Some fields on this form has invalid values.  Please correct them and try again.',
};

export const MediaQueries = {
  Xs: `(min-width: 320px)`,
  Sm: `(min-width: 640px)`,
  Md: `(min-width: 768px)`,
  Lg: `(min-width: 1024px)`,
  Xl: `(min-width: 1280px)`,
  XXL: `(min-width: 1536px)`,
};