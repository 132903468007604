import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontFamilies,
  FontSizes,
  Theme,
  MediaQueries,
} from 'common';

import {
  Arrow,
  Button,
  Text
} from 'common/components';

const Container = styled.div``;

const Header = styled.div`
  background-color: ${Theme.Card.Background};
  color: ${Theme.Text.Color};
  font-family: ${FontFamilies.Bold};
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;

  @media only screen and ${MediaQueries.Md} {
    font-size: ${FontSizes.Bigger};
    padding: 20px 20px 15px 20px;
  }
`;

const Row = styled.div`
  display: flex;
  position: relative;
  padding: ${props => props.padding || '10px 20px'};
  width: 100%;
  ${props => props.desktopOnly && 'display: none;'}

  @media only screen and ${MediaQueries.Md} {
    display: flex;
  }
`;

const Col = styled.div`
  color: ${Colors.White};
  ${props => props.width && `width: ${props.width};`}
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
`;

const Divide = styled.div`
  background-color: ${Theme.Divider.Color};
  height: 1px;
`;

const DesktopDivide = styled.div`
  display: none;

  @media only screen and ${MediaQueries.Md} {
    display: block;
    background-color: ${Theme.Divider.Color};
    height: 1px;
  }
`;


const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EventMobileTicketSummary = props => {

  const totals = props.totals || {};

  const selectedTickets = totals?.selectedTickets
    ? Object.values(totals?.selectedTickets)
    : [];

  const selectedMerch = totals?.selectedMerch
    ? Object.values(totals?.selectedMerch)
    : [];

  const onCheckoutClick = () => {
    props.onCheckoutClick && props.onCheckoutClick();
  };

  const ticketCategories = [];

  for (const ticket of selectedTickets) {

    if (!ticket.count) {
      continue;
    }

    ticketCategories.push(
      <Row
        key={ticket.id}>

        <Col flex={'1'}>
        
          <Text padding={'0 10px 0 0'} fontSize={FontSizes.Small}>{ticket.title}</Text>

        </Col>

        <Col width={'45px'}>
          
          <Text fontSize={FontSizes.Small} padding={'0 6px'}>X{ticket.count}</Text>

        </Col>

        <Col width={'85px'} textAlign={'right'}>
          
          <Text fontSize={FontSizes.Small}>R{ticket.totalPrice.toFixed(2)}</Text>
          
        </Col>

      </Row>
    );
  }

  for (const merch of selectedMerch) {

    if (!merch.count) {
      continue;
    }

    ticketCategories.push(
      <Row
        key={`merch-${merch.product_variant_id}`}>

        <Col flex={'1'}>
        
          <Text padding={'0 10px 0 0'} fontSize={FontSizes.Small}>{merch.product_name} - {merch.option_value}</Text>

        </Col>

        <Col width={'45px'}>
          
          <Text fontSize={FontSizes.Small} padding={'0 6px'}>X{merch.count}</Text>

        </Col>

        <Col width={'85px'} textAlign={'right'}>
          
          <Text fontSize={FontSizes.Small}>R{merch.totalPrice.toFixed(2)}</Text>
          
        </Col>

      </Row>
    );
  }

  return (

    <Container>

      <Header>
        
        <span>Your Order</span>
        
        { props.onArrowClick &&

          <ArrowContainer
            onClick={props.onArrowClick}>

            <Arrow
              active={true}
              size={'10px'}
              direction={'down'}
            />

          </ArrowContainer>
        }

      </Header>

      <Divide/>

      { ticketCategories.length
        
        ? <div style={{ overflow: 'auto' }}>{ticketCategories}</div>
        
        : <Row>
            <Text fontSize={FontSizes.Small}>No items selected</Text>
          </Row>
      }

      <Divide/>

      <Row>

        <Col flex={'1'}>

          <Text fontFamily={FontFamilies.Bold} fontSize={FontSizes.Small}>Total:</Text>

        </Col>

        <Col width={'45px'}>

          <Text fontFamily={FontFamilies.Bold} fontSize={FontSizes.Small} padding={'0 6px'}>X{totals.tickets}</Text>

        </Col>

        <Col width={'85px'} textAlign={'right'}>

          <Text fontFamily={FontFamilies.Bold} fontSize={FontSizes.Small}>R{totals.price.toFixed(2)}</Text>
          
        </Col>

      </Row>

      <DesktopDivide/>

      { props.children &&
      
        <Row padding={'20px'}>
          {props.children}
        </Row>
      }

      { !props.hideCheckoutButton &&
      
        <Row padding={'20px'} desktopOnly>

          <Button
            onClick={onCheckoutClick}
            width={'100%'}>
              Checkout
          </Button>

        </Row>
      }

    </Container>
  );
};